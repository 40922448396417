import { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { SzIcon, SzTab, SzTypographie } from "@suezenv/react-theme-components";
import { useTranslationWithPrefix } from "../../../hooks/translation";
import { useResponsive } from "../../../hooks/responsive";
import signalService from "../../../services/api/signal-service";
import { IAttachment, SignalRequest } from "../../../type/signal.type";
import SignalComments from "./tabs/comments";
import SignalPictures, { ISignalPicture } from "./tabs/pictures";

interface ISignalTabs {
    signal: SignalRequest;
    selectedTab: string;
    addComment: () => void;
    changeTab: (selectedTab: string) => void;
}

const ATTACHMENT_DOWNLOAD = '/api/signal/:signalId/attachment/:attachmentId/download'

const SignalTabs: React.FC<ISignalTabs> = ({ signal, selectedTab, addComment, changeTab }) => {
    const [collapsedTab, setCollapsedTab] = useState("");
    const selectTabHandle = (seleted: string) => {
        changeTab(seleted);
        if (collapsedTab === seleted) {
            setCollapsedTab("");
        } else {
            setCollapsedTab(seleted);
        }
    };
    const { isMobile } = useResponsive();
    const trans = useTranslationWithPrefix("signalement", "detail_signal");
    const [pictures, setPictures] = useState<ISignalPicture[]>([]);
    const [countAttachments, setCountAttachments] = useState(0);

    useEffect(() => {
        const attachments: ISignalPicture[] = [];
        const mime = require('mime-types');
        const countAttachments = signal.attachments.length;
        setCountAttachments(countAttachments);
        const asyncRes = Promise.all(signal.attachments.map(async (attachment: IAttachment) => {

            const link = ATTACHMENT_DOWNLOAD.replace(':signalId', signal.id).replace(':attachmentId', attachment.file.id)
            const extension = mime.extension(attachment.file.mimeType);
                        
            return await signalService
                // download picture
                .getAttachment(signal.id, attachment.file.id)
                .then((response: AxiosResponse) => {
                    const fileReaderInstance = new FileReader();
                    fileReaderInstance.readAsDataURL(response.data);
                    fileReaderInstance.onload = () => {
                        const base64data = String(fileReaderInstance.result);
                        attachments.push({
                            data: base64data,
                            fileName: attachment.file.originalFileName,
                            link: link,
                            extension: extension
                        });
                    };
                })
                // if file is not a picture
                .catch(() => {
                  attachments.push({
                    data: "",
                    fileName: attachment.file.originalFileName,
                    link: link,
                    extension: extension });
                });
           })
        );
        asyncRes.then(()=>{
            setTimeout(() => {
                setPictures(attachments)
            }, 1000);
        })
    }, [signal.id]);
    return (
        <div className={`mb-4 ${selectedTab}`}>
            <SzTab
                activeKey={selectedTab}
                id="signal-detail-tabs"
                className="signal-detail-tabs cursor-pointer mb-3 d-flex"
                selectTabHandle={selectTabHandle}
            >
                <SzTab.SzTabContent
                    eventKey="picture-tab"
                    title={
                        <SzTypographie variant="body">
                            {isMobile && (
                                <SzIcon
                                    variant="line"
                                    icon={`${collapsedTab === "picture-tab" ? "arrow-up-1" : "arrow-down-1"}`}
                                    className="ml-0 mr-2"
                                />
                            )}
                            {trans("attachments")}
                        </SzTypographie>
                    }
                >
                <SignalPictures pictures={pictures} countAttachments={countAttachments}/>
                </SzTab.SzTabContent>
                <SzTab.SzTabContent
                    eventKey="comment-tab"
                    title={
                        <SzTypographie variant="body">
                            {isMobile && (
                                <SzIcon
                                    variant="line"
                                    icon={`${collapsedTab === "comment-tab" ? "arrow-up-1" : "arrow-down-1"}`}
                                    className="ml-0 mr-2"
                                />
                            )}
                            {trans("comments")}
                        </SzTypographie>
                    }
                >
                    <SignalComments signal={signal} addComment={addComment} />
                </SzTab.SzTabContent>
            </SzTab>
        </div>
    );
};
export default SignalTabs;

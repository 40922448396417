import axios from "axios";
import { Request } from "../Request";
import instance from "../AppRequest";
import { AxiosResponse } from "axios";
import { IStatusStats, SignalRequest, SignalResponseFormat, StatusIdType } from "../../type/signal.type";
import { IAddress } from "../../type/Address.type";
class SignalService {
    private axiosService: Request;

    /**
     * @param request
     */
    constructor(request: any) {
        this.axiosService = request;
    }

    getAll(
        queryParams?: any,
        format: SignalResponseFormat = "json",
    ): Promise<AxiosResponse<SignalRequest[] | GeoJSON.FeatureCollection>> {
        let config = {};
        if (queryParams) {
            config = {
                params: queryParams,
            };
        }
        return this.axiosService.get(`/api/signal.${format}`, false, false, true, config);
    }

    getAllAsGeoJson(queryParams?: any): Promise<AxiosResponse<SignalRequest[]>> {
        let config = {};
        if (queryParams) {
            config = {
                params: queryParams,
            };
        }
        return this.axiosService.get(`/api/signal`, false, false, true, config);
    }
    getOne(id: string): Promise<AxiosResponse<SignalRequest>> {
        return this.axiosService.get(`/api/signal/${id}?withStatuses=true&withAttachments=true`);
    }

    createSignal(
        organizationId: string,
        requestType: string,
        data: { [key: string]: string },
        address: IAddress,
        comment: string,
    ) {
        return this.axiosService.post(`/api/organization/${organizationId}/signal`, {
            requestType,
            comment,
            address,
            data,
        });
    }

    addAttachments(uri: string, attachments: Array<string>) {
        attachments.forEach((attachmentRaw) => {
            const formData = new FormData();
            fetch(attachmentRaw)
                .then((res) => res.blob())
                .then((blob) => {
                    const file = new File([blob], `attachment-${Date.now()}`, { type: blob.type });
                    formData.append("attachment", file);
                    this.axiosService.post(uri + "/attachment", formData, false, {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            Accept: "*/*",
                        },
                    });
                });
        });
    }

    // get image preview
    getAttachment(signalId: string, attachmentId: string) {
        return this.axiosService.get(`/api/signal/${signalId}/attachment/${attachmentId}`, false, false, true, {
            responseType: "blob",
        });
    }

    downloadAttachment(url: string) {
        return this.axiosService.get(url, false, false, true, {
          responseType: "blob",
        });
    }

    updateStatus(signalId: string, status: StatusIdType, comment: string): Promise<AxiosResponse> {
        return this.axiosService.post(`/api/signal/${signalId}/status`, {
            status,
            comment,
        });
    }
    addComment(signalId: string, comment: string): Promise<AxiosResponse> {
        return this.axiosService.post(`/api/signal/${signalId}/comment`, {
            comment,
        });
    }
    batchStatusUpdate = (signals: SignalRequest[], status: StatusIdType, comment: string) => {
        const batchRequests = signals.map((signal: SignalRequest) => this.updateStatus(signal.id, status, comment));
        return axios.all(batchRequests);
    };

    getStatusStats = (queryParams?: any): Promise<AxiosResponse<IStatusStats>> => {
        let config = {};
        if (queryParams) {
            config = {
                params: queryParams,
            };
        }
        return this.axiosService.get(`/api/statistics/status`, false, false, true, config);
    };
}

export default new SignalService(instance);

import { useMediaQuery } from "react-responsive";

enum BREAKPOINTS {
    xs = 0,
    sm = 576,
    md = 768,
    lg = 992,
    xl = 1200,
}

export const useResponsive = (): { isMobile: boolean; isTablet: boolean; isComputer: boolean } => {
    const isMobile = useMediaQuery({ query: `(max-device-width: ${BREAKPOINTS.md + 1}px)` });
    const isTablet = useMediaQuery({ query: `(max-device-width: ${BREAKPOINTS.md}px)` });
    const isComputer = useMediaQuery({ query: `(min-width: ${BREAKPOINTS.xl}px)` });

    return { isMobile, isTablet, isComputer };
};

import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import L, { LatLngExpression, LatLngTuple } from "leaflet";
import { FeatureGroup, GeoJSON, MapContainer, TileLayer, WMSTileLayer, ZoomControl } from "react-leaflet";
import SignalContext from "../../../context/signal.context";
import "./signal-map.scss";
import SignalTypeMarker from "./signal-type-marker";
import { SignalGeoJSONProperties } from "../../../type/signal.type";
import useSignalCategory from "../../../hooks/signal-category";
import usePin from "../../../hooks/pin-hooks";
import { RequestTypeReference } from "../../../type/organization.type";
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { v4 as uuidv4 } from 'uuid';
import FitBoundMap from "./FitBoundMap";
import { STATUS } from "../../../constants/status";

interface IMapComponent {
    requestTypes?: RequestTypeReference[];
    geojsonData?: GeoJSON.FeatureCollection<GeoJSON.Point, SignalGeoJSONProperties>;
    geoShape?: GeoJSON.Feature,
    defaultZoom: number;
    center: LatLngTuple;
    children?: React.ReactNode;
}

const SignalMap: React.FC<IMapComponent> = ({ requestTypes, defaultZoom, center, geojsonData, geoShape, children }) => {
    const signalContext = useContext(SignalContext);
    const getSignalCategorie = useSignalCategory(requestTypes);
    const getPin = usePin();
    const isDepartementDataProvided = useMemo(() => {
        return signalContext.geoServer?.url && signalContext.geoServer?.workspace && signalContext.geoServer?.layer;
    }, [signalContext]);
    const [bounds, setBounds] = useState<any>(null);
    const featureGroupRef: any = useRef(null);

    useEffect(() => {
      setTimeout(function () {
        if (featureGroupRef.current) {
          setBounds(featureGroupRef.current.getBounds());
        }
      }, 1000)
    }, [featureGroupRef.current])

    window.dispatchEvent(new Event('resize'));

    const createClusterCustomIcon = (cluster: any) => {
      // Only new and approved status are displayed
      // So by default, its approved status classname and if there is only one new status, we apply new status classname
      let className = 'marker-cluster-custom';
      const markers = cluster.getAllChildMarkers();
      let displayStatus = {
        new: false,
        approved: false,
        rejected: false,
        closed: false
      }

      markers.map((marker: L.Marker) => {
        const icon = marker.getIcon();

        Object.entries(STATUS).map((status) => {
          const value = status[1];
          if (undefined !== icon.options.className && icon.options.className.indexOf(value) > 0) {
            displayStatus[value as keyof typeof displayStatus] = true;
          }
        })
      });

      if (displayStatus.new) {
        className = "marker-cluster-custom"
      } else if (displayStatus.approved) {
        className = "marker-cluster-custom__approved"
      } else if (displayStatus.rejected) {
        className = "marker-cluster-custom__rejected"
      } else if (displayStatus.closed) {
        className = "marker-cluster-custom__closed"
      }

      return L.divIcon({
        html: `<span>${cluster.getChildCount()}</span>`,
        className: className,
        iconSize: L.point(28, 28, true),
      });
    };

    return (
         
        <MapContainer
            className="signal-map-container"
            center={center}
            zoom={defaultZoom}
            scrollWheelZoom={true}
            zoomControl={false}
        >
            <ZoomControl position="topleft" />
            <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {isDepartementDataProvided && (
                <WMSTileLayer
                    params={{
                        format: "image/png",
                        layers: `${signalContext.geoServer?.workspace}:${signalContext.geoServer?.layer}`,
                        transparent: true,
                        service: "WMS",
                    }}
                    url={`${signalContext.geoServer?.url}/wms?authkey=${signalContext.geoServer?.authkey}`}
                    opacity={0.4}
                    version="1.1.1"
                />
            )}
            {children && children}
            <FeatureGroup ref={featureGroupRef}>
                {geoShape && <GeoJSON
                    data={geoShape}
                    style={() => ({
                        opacity: 0,
                        fillOpacity: 0,
                        interactive: false
                    })}/>}
            </FeatureGroup>
            {geojsonData && geojsonData.features &&
              <MarkerClusterGroup
                key={uuidv4()}
                iconCreateFunction={createClusterCustomIcon}
              >
                { geojsonData.features.map((features: GeoJSON.Feature<GeoJSON.Point, SignalGeoJSONProperties>) => {
                    return (
                        features.properties.requestType && (
                            <SignalTypeMarker
                                key={features.properties["id"]}
                                signalId={features.properties.id}
                                currentStatusId={features.properties["currentStatus.status.id"]}
                                currentStatusLabel={features.properties["currentStatus.status.label"]}
                                coordinate={
                                    [
                                        features.geometry.coordinates[1],
                                        features.geometry.coordinates[0],
                                    ] as LatLngExpression
                                }
                                fullAddress={features.properties["address.address"]}
                                fullName={features.properties["applicationUser.fullName"]}
                                pin={getPin(getSignalCategorie(features.properties.requestType)?.pinCode)}
                            />
                        )
                    );
                })
              }
            </MarkerClusterGroup>}
            {geoShape && bounds &&
              <FitBoundMap bounds={bounds}/>
            }
        </MapContainer>
    );
};

export default SignalMap;

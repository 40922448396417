import React from "react";
import { SzTypographie } from "@suezenv/react-theme-components";
import { useTranslationWithPrefix } from "../../../hooks/translation";
import "./map-tooltip.scss";

interface IMapTooltip {
    currentStatusLabel: string;
    fullAddress: string;
    fullName: string;
}
const MapTooltip: React.FC<IMapTooltip> = ({ currentStatusLabel, fullAddress, fullName }) => {
    const trans = useTranslationWithPrefix("signalement", "view_signal.tooltip");

    return (
        <div className="row map-tooltip">
            <div className="col mx-0 mt-0">
                <div className="title p-2 m-0">
                    <SzTypographie variant="body" weight="bold" className="m-0">
                        {trans("title")}
                    </SzTypographie>
                </div>
                <div className="row p-2">
                    <div className="col">
                        <div className="row  mb-2">
                            <div className="col">
                                <SzTypographie variant="caption">{trans("status")}</SzTypographie>
                                <SzTypographie variant="body" weight="medium" className="mb-2">
                                    {currentStatusLabel}
                                </SzTypographie>
                            </div>
                        </div>

                        <div className="row  mb-2">
                            <div className="col">
                                <SzTypographie variant="caption">{trans("signalant")}</SzTypographie>
                                <SzTypographie variant="body" weight="medium" className="mb-2">
                                    {fullName}
                                </SzTypographie>
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col">
                                <SzTypographie variant="caption">{trans("address")}</SzTypographie>
                                <SzTypographie variant="body" weight="medium" className="mb-2">
                                    {fullAddress}
                                </SzTypographie>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default MapTooltip;

import axios, { AxiosRequestConfig } from "axios";
import { setupCache } from "axios-cache-adapter";
import { config } from "../config";
import Qs from "qs";

export class Request {
    public static cancel: any;
    private base: string;
    private CancelToken: any;
    private cache: any;
    private onFullFilled?: (response?: any) => any;
    private onError?: (error?: any) => any;
    private beforeRequest?: () => any;

    constructor(beforeRequest?: () => any, onFullFilled?: (response?: any) => any, onError?: (error?: any) => any) {
        this.base = config.apiUrl as string;
        this.CancelToken = axios.CancelToken;
        this.cache = setupCache({
            maxAge: 15 * 60 * 1000,
        });

        this.onError = onError;
        this.onFullFilled = onFullFilled;
        this.beforeRequest = beforeRequest;
    }

    public async get<T = any>(
        endpoint: string,
        cancelOption = false,
        cached = true,
        showLoading = true,
        params: Partial<AxiosRequestConfig> = {},
        disbaleAutorisation = false,
    ) {
        return this.sendRequest<T>(endpoint, cancelOption, cached, showLoading, params, disbaleAutorisation);
    }

    public async post(endpoint: string, body: any, cancelOption = false, params = {}) {
        return this.postRequest(endpoint, body, cancelOption, params);
    }
    public async patch(endpoint: string, body: any, cancelOption = false) {
        return this.patchRequest(endpoint, body, cancelOption);
    }
    public async put(endpoint: string, body: any, cancelOption = false) {
        return this.putRequest(endpoint, body, cancelOption);
    }
    public async delete(endpoint: string, cancelOption = false) {
        return this.deleteRequest(endpoint, cancelOption);
    }
    public async getBlob(endpoint: string, cancelOption: boolean, cached = true) {
        this.cancelRequest();
        const httpInstance = this.getHttpInstance(cached);
        const param = { responseType: "blob" as "blob" };
        return httpInstance.get(endpoint, param);
    }

    public async refreshToken() {
        const body = JSON.stringify({
            refresh_token: localStorage.getItem("refreshToken"),
        });
        const endpoint = `/token/refresh`;
        const httpInstance = this.getHttpInstance();
        return httpInstance.post(endpoint, body);
    }

    public async checkJWT() {
        const endpoint = `/api/token-check`;
        const httpInstance = this.getHttpInstance();

        return httpInstance.get(endpoint);
    }

    public cancelRequest() {
        if (typeof Request.cancel === "function") {
            Request.cancel();
        }
    }

    private sendRequest<T = any>(
        endpoint: string,
        cancelOption = false,
        cached = true,
        showLoading = true,
        overrideParams = {},
        disbaleAutorisation = false,
    ) {
        this.cancelRequest();
        let param = {};
        if (cancelOption) {
            param = {
                cancelToken: new this.CancelToken(function executor(c: any) {
                    Request.cancel = c;
                }),
            };
        }

        param = {
            ...param,
            ...overrideParams,
            paramsSerializer: (params: any) => {
                return Qs.stringify(params);
            },
        };

        const httpInstance = this.getHttpInstance(cached, showLoading, disbaleAutorisation);

        return httpInstance.get<T>(endpoint, param);
    }

    private postRequest(endpoint: string, body: any, cancelOption = false, overrideParams = {}) {
        this.cancelRequest();
        let param = {};
        if (cancelOption) {
            param = {
                cancelToken: new this.CancelToken(function executor(c: any) {
                    Request.cancel = c;
                }),
            };
        }

        param = {
            ...param,
            ...overrideParams,
            paramsSerializer: (params: any) => {
                return Qs.stringify(params);
            },
        };
        const httpInstance = this.getHttpInstance();
        return httpInstance.post(endpoint, body, param);
    }
    private patchRequest(endpoint: string, body: any, cancelOption = false) {
        this.cancelRequest();
        let param = {};
        if (cancelOption) {
            param = {
                cancelToken: new this.CancelToken(function executor(c: any) {
                    Request.cancel = c;
                }),
            };
        }
        const httpInstance = this.getHttpInstance();
        return httpInstance.patch(endpoint, body, param);
    }
    private putRequest(endpoint: string, body: any, cancelOption = false) {
        this.cancelRequest();
        let param = {};
        if (cancelOption) {
            param = {
                cancelToken: new this.CancelToken(function executor(c: any) {
                    Request.cancel = c;
                }),
            };
        }
        const httpInstance = this.getHttpInstance();
        return httpInstance.put(endpoint, body, param);
    }

    private deleteRequest(endpoint: string, cancelOption = false) {
        this.cancelRequest();
        let param = {};
        if (cancelOption) {
            param = {
                cancelToken: new this.CancelToken(function executor(c: any) {
                    Request.cancel = c;
                }),
            };
        }
        const httpInstance = this.getHttpInstance();
        return httpInstance.delete(endpoint, param);
    }

    private getHttpInstance(cached?: boolean, showLoading = true, disbaleAutorisation = false) {
        const { base } = this;
        let adapter = this.cache.adapter;

        if ("undefined" !== typeof cached && !cached) {
            adapter = undefined;
        }

        if (showLoading) {
            this.beforeRequest?.();
        }

        const headers: any = {
            "Content-Type": "application/json;charset=utf-8",
        };

        if (!disbaleAutorisation) {
            if (localStorage.getItem(config.tokenKey)) {
                headers.authorization = `Bearer ${localStorage.getItem(config.tokenKey)}`;
            } else {
                headers.authorization = `Bearer ${process.env.REACT_APP_TOKEN_KEY}`;
            }
        }

        const httpInstance = axios.create({
            adapter,
            baseURL: `${base}`,
            headers,
        });

        httpInstance.interceptors.response.use(
            async (response) => {
                this.onFullFilled?.(response);

                return response;
            },
            (error) => {
                this.onError?.(error);

                return Promise.reject(error);
            },
        );

        return httpInstance;
    }
}

export default new Request();

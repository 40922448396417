import { Request } from "../Request";
import instance from "../AppRequest";
import { AxiosResponse } from "axios";

class FormSchemaService {
    private axiosService: Request;

    /**
     * @param request
     */
    constructor(request: any) {
        this.axiosService = request;
    }
    getSchema(organizationId: string, requestTypeId: string): Promise<AxiosResponse> {
        const url = `/api/organization/${organizationId}/requestType/${requestTypeId}/schema`;
        return this.axiosService.get(url);
    }
    getUiSchema(organizationId: string, requestTypeId: string): Promise<AxiosResponse> {
        const url = `/api/organization/${organizationId}/requestType/${requestTypeId}/uischema`;
        return this.axiosService.get(url);
    }
}

export default new FormSchemaService(instance);

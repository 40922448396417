import React, { useContext, useEffect, useState } from "react";
import { SzBox, SzButton, SzDropDown, SzTypographie } from "@suezenv/react-theme-components";
import SignalContext from "../../../context/signal.context";
import { useTranslationWithPrefix } from "../../../hooks/translation";
import { OptionAction, OptionActionType , ISignalStatus} from "../../../type/signal.type";
import "./signal-list-header.scss";
import { STATUS } from "../../../constants/status";

interface IOption {
    content: string;
    handleClick: (e: any) => void;
}
interface SignalListHeaderProps {
    possibleStatusAction: OptionAction[];
    statusCount?: ISignalStatus;
    onOptionClicked: (action: OptionActionType) => void;
}
const SignalListHeader: React.FC<SignalListHeaderProps> = ({ possibleStatusAction, statusCount, onOptionClicked }) => {
    const trans = useTranslationWithPrefix("signalement", "list_signal.header");
    const signalContext = useContext(SignalContext);
    const [options, setOptions] = useState<IOption[]>([]);

    useEffect(() => {
        const newOptions: IOption[] = [];
        possibleStatusAction.forEach((status: OptionAction) => {
            newOptions.push({
                content: trans(`${status.id}_action`),
                handleClick: (e: any) => {
                    e.stopPropagation();
                    onOptionClicked(status.id);
                },
            });
        });
        setOptions([...newOptions]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [possibleStatusAction]);

    return (
        <div className="signal-list-header row d-none d-sm-flex ml-3 px-5">
            <div className="col mr-2 mt-2">
                {<SzBox className="row justify-content-end bg-white">
                    {statusCount &&
                        Object.values(STATUS).map((statusId: string) => (
                            <div key={statusId} className="col-auto">
                                <div className="row">
                                    <div className="col-auto">
                                        <span className={`dot ${statusId}`} />
                                    </div>
                                    <div className="col-auto pl-0">
                                        <SzTypographie className="mb-0" weight="medium" variant="body">
                                            {trans(statusId, {
                                                count: statusCount[statusId] ?? '0',
                                            })}
                                        </SzTypographie>
                                    </div>
                                </div>
                            </div>
                        ))}
                </SzBox>}

                {/* header list */}
                <SzBox className="row px-2">
                    <div className="col-5">
                        <div className="row align-items-center">
                            <div className="p-0 m-0">
                                <SzButton variant="tertiary" className="mr-3 pl-3" alignIcon="right">
                                    {trans("category")} / {trans("date")}
                                </SzButton>
                            </div>
                        </div>
                    </div>
                    <div className="col-3 p-0 m-0">
                        <SzButton variant="tertiary" className="mr-3" alignIcon="right">
                            {trans("location")}
                        </SzButton>
                    </div>
                    <div className={"col-4 p-0 m-0 "+(signalContext.enableBatchExport ? " col-lg-1" : "col-lg-4")}>
                        <SzButton variant="tertiary" className="mr-3" alignIcon="right">
                            {trans("status")}
                        </SzButton>
                    </div>

                    {signalContext.enableBatchExport && (
                        <div className="col-lg-3 d-none d-lg-block p-0 m-0">
                            <div className="row justify-content-end">
                                <div className="col-auto my-auto mx-0 p-0">{trans("options")}</div>
                                <div className="col-auto m-0 p-0">
                                    <SzDropDown className="col-4" alignRight={true}>
                                        {options.length > 0 ? (
                                            options.map(({ handleClick, content }, index) => {
                                                return (
                                                    <SzDropDown.Item key={index} onClick={handleClick}>
                                                        <div className="btn">{content} </div>
                                                    </SzDropDown.Item>
                                                );
                                            })
                                        ) : (
                                            <div className="btn">
                                                <SzTypographie variant="body" weight="light" className="mb-3">
                                                    {trans("no_action")}
                                                </SzTypographie>
                                            </div>
                                        )}
                                    </SzDropDown>
                                </div>
                            </div>
                        </div>
                    )}
                </SzBox>
            </div>
        </div>
    );
};

export default SignalListHeader;

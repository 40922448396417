import React from "react";
import { SzBox } from "@suezenv/react-theme-components";
import { IPropertie } from "../object-field-template-stepper";
interface IComplementFormProps {
    properties: IPropertie[];
}
const ComplementForm: React.FC<IComplementFormProps> = ({ properties }) => {
    return (
        <div>
            {properties.map((propertie: IPropertie, index: number) => (
                <SzBox key={index} className="row bg-white p-2 mb-5 ">
                    <div className="col">{propertie.content}</div>
                </SzBox>
            ))}
        </div>
    );
};

export default ComplementForm;

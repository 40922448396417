import { Request } from "../Request";
import instance from "../AppRequest";
import { AxiosResponse } from "axios";
import { RequestTypeReference } from "../../type/organization.type";

class RequestTypeReferenceRepository {
    private axiosService: Request;

    /**
     * @param request
     */
    constructor(request: any) {
        this.axiosService = request;
    }

    getAll(organizationId: string): Promise<AxiosResponse<RequestTypeReference[]>> {
        return this.axiosService.get(`/api/organization/${organizationId}/requestTypes`);
    }
}

export default new RequestTypeReferenceRepository(instance);

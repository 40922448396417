import React from "react";
import { SzIcon, SzInput } from "@suezenv/react-theme-components";
import { SzInputProps } from "@suezenv/react-theme-components/build/components/SzInput/SzInput";
import "./SzInputWithClear.scss";

interface Props extends SzInputProps {
    onClear: () => void;
}

const SzInputWithClear: React.FC<Props> = ({ onClear, ...props }) => {
    return (
        <div className="position-relative sz-input-with-clear">
            <SzInput {...props} />
            <span className="clear-search-btn cursor-pointer">
                {props.value && <SzIcon icon="remove" onClick={() => onClear()} variant="line" />}
            </span>
        </div>
    );
};

export default SzInputWithClear;

import { savePDF } from "@progress/kendo-react-pdf";

class PdfService {
    createPdf = (html: any, name: string, callBack?: () => void) => {
        savePDF(
            html,
            {
                scale: 0.6,
                paperSize: "A4",
                fileName: name,
                margin: 15,
            },
            callBack,
        );
    };
}

const Doc = new PdfService();
export default Doc;

import { ISignalFilter } from "../type/signal.type";

export const DEFAULT_STATUS_FILTER: ISignalFilter = {
    status: [
        {
            value: "new",
            label: "Nouveau",
        },
        {
            value: "approved",
            label: "Accepté",
        },
        {
            value: "closed",
            label: "fermé",
        },
        {
            value: "rejected",
            label: "rejeté",
        },
    ],
};
export const PAGINATION_NUMBER_PER_PAGE = 15;
export const SORT_FIELD_DEFAULT = 'createdAt';
export const SORT_ORDER_DEFAULT = 'desc';

import React, { useEffect, useState } from "react";
import { SzBox, SzPagination } from "@suezenv/react-theme-components";
import {
    ISignalStatus,
    OptionAction,
    OptionActionType,
    SignalRequest,
    Status,
    StatusChangeActionType,
    IPagination,
    ISort,
} from "../../../type/signal.type";
import SignalListItem from "./signal-list-item";
import "./signal-list.scss";
import SignalListHeader from "./signal-list-header";
import { RequestTypeReference, WorkflowStatusReference } from "../../../type/organization.type";
import useSignalCategory from "../../../hooks/signal-category";
import ActionModal from "../../signal-detail/sections/action-modal";
import signalService from "../../../services/api/signal-service";
import { UserType } from "../../../type/user.type";
import { LIST_DEFAULT_PAGE, LIST_PAGINATION_RANGE } from "../../../constants/list";

interface  ISignalList {
    signals: SignalRequest[];
    catergories: RequestTypeReference[];
    statusStats?: ISignalStatus;
    user: UserType;
    workflowStatus: WorkflowStatusReference[];
    pagination: IPagination;
    sort: ISort;
    getSignals: (cuurentPage?: number) => void;
}

const SignalList: React.FC<ISignalList> = ({
    signals,
    catergories,
    statusStats,
    user,
    workflowStatus,
    pagination,
    getSignals,
}) => {
    const [checkedSignals, setCheckedSignal] = useState<SignalRequest[]>([]);
    const [possibleStatusAction, setPossibleStatusAction] = useState<OptionAction[]>([]);
    const getSignalCategorie = useSignalCategory(catergories);
    const [currentPage, setCurrentPage] = useState(LIST_DEFAULT_PAGE);

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
        getSignals(pageNumber);
    };
    // modal
    const [showModal, setShowModal] = useState(false);
    const [modalAction, setModalAction] = useState<StatusChangeActionType>("approved");
    const showActionModal = (actionId: OptionActionType) => {
        if (actionId !== "exported") {
            setModalAction(actionId);
            setShowModal(true);
        }
    };
    const batchUpdateStatus = (action: StatusChangeActionType, comment: string) => {
        // update Status
        if (action !== "comment") {
            signalService.batchStatusUpdate(checkedSignals, action, comment).finally(() => {
                getSignals();
                setCheckedSignal([]);
            });
        }
    };

    useEffect(() => {
        // define the possible status
        if (checkedSignals.length > 0) {
            const possibleStatus = checkedSignals
                .map<Status[]>((checkedSignal: SignalRequest) => checkedSignal.nextPossibleStatuses)
                .reduce((accumulator: Status[], currentValue: Status[]) => {
                    return statusIntersection(accumulator, currentValue);
                });
            setPossibleStatusAction([
                {
                    id: "exported",
                    label: "",
                },
                ...possibleStatus,
            ]);
        } else {
            setPossibleStatusAction([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkedSignals]);

    const findSignalIndex = (signal: SignalRequest) => {
        // return the index of a given signal in the checkSignal list
        const index = checkedSignals.findIndex((checkedSignal: SignalRequest) => {
            return checkedSignal.id === signal.id;
        });
        return index;
    };

    const statusIntersection = (array1: Status[], array2: Status[]) => {
        // return common status bettween two arrays o status
        const result = array1.filter((status1) => array2.some((status2) => status1.id === status2.id));
        return result;
    };
    const isChecked = (signal: SignalRequest) => {
        // check if a signal is checked or not
        return findSignalIndex(signal) !== -1 ? true : false;
    };
    const onItemChanged = (signal: SignalRequest): void => {
        const indexOfSignal = findSignalIndex(signal);
        if (indexOfSignal !== -1) {
            checkedSignals.splice(indexOfSignal, 1);
            setCheckedSignal([...checkedSignals]);
        } else {
            setCheckedSignal([...checkedSignals, signal]);
        }
    };

    return (
        <SzBox className={`signal-list mt-3 mb-5 bg-white`} tag="div">
            <SignalListHeader
                possibleStatusAction={possibleStatusAction}
                statusCount={statusStats}
                onOptionClicked={showActionModal}
            />
            <div className="mb-4 pb-4">
                {signals.map((signal: SignalRequest, index: number) => (
                    <SignalListItem
                        key={signal.id}
                        signal={signal}
                        category={getSignalCategorie(signal.requestType)}
                        checked={isChecked(signal)}
                        onItemChange={onItemChanged}
                        index={index}
                    />
                ))}
                {signals.length > 0 && (
                    <div className="row justify-content-around">
                        <div className="col-auto">
                            <SzPagination
                                totalItemsCount={pagination?.totalCount}
                                activePage={currentPage}
                                onChange={handlePageChange}
                                itemsCountPerPage={pagination?.perPage}
                                pageRangeDisplayed={LIST_PAGINATION_RANGE}
                            />
                        </div>
                    </div>
                )}
            </div>
            <div>
                <ActionModal
                    show={showModal}
                    action={modalAction}
                    setShow={setShowModal}
                    validate={batchUpdateStatus}
                    customAction={()=>{}}
                />
            </div>
        </SzBox>
    );
};

export default SignalList;

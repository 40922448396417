import React from "react";
import L, { DivIcon } from "leaflet";
import ReactDOMServer from "react-dom/server";

class SignalDivIconGenerator {
    private className: string;
    private iconSize: L.PointExpression;
    private iconAnchor: L.PointExpression;
    private popupAnchor: L.PointExpression;
    constructor(
        className: string = "",
        iconSize: L.PointExpression = [30, 30],
        iconAnchor: L.PointExpression = [15, 30],
        popupAnchor: L.PointExpression = [5, 55],
    ) {
        this.className = className;
        this.iconSize = iconSize;
        this.iconAnchor = iconAnchor;
        this.popupAnchor = popupAnchor;
    }
    generateDivIcon = (svgPin?: JSX.Element | string): DivIcon | undefined => {
        let svgHtml = "";
        if (typeof svgPin === "string") {
            svgHtml = svgPin;
        }
        if (React.isValidElement(svgPin)) {
            svgHtml = ReactDOMServer.renderToStaticMarkup(svgPin);
        }
        return L.divIcon({
            html: svgHtml,
            iconAnchor: this.iconAnchor,
            popupAnchor: this.popupAnchor,
            iconSize: this.iconSize,
            className: `signal-icon ${this.className}`,
        });
    };
}

export default SignalDivIconGenerator;

import React from "react";
import { useTranslationWithPrefix } from "../../hooks/translation";
import UnauthorizedIcon from "./icons/unauthorized-icon";

const Unauthorized: React.FC = () => {
    const trans = useTranslationWithPrefix("common", "error");
    return (
        <div className="row h-100 justify-content-around ">
            <div className="col-auto">
                <div className="row">
                    <div className="col">
                        <UnauthorizedIcon></UnauthorizedIcon>
                    </div>
                </div>
                <div className="row">
                    <div className="col-auto text-center">{trans("unauthorized")}</div>
                </div>
            </div>
        </div>
    );
};

export default Unauthorized;

import React from "react";
import "./autocomplete.scss";
interface IAutoComplete {
    items: string[];
    onClickHandler: (index: number) => void;
    className?: string;
}

const AutoComplete: React.FC<IAutoComplete> = ({ items, onClickHandler, className }) => {
    return (
        <div className={`autocomplete position-absolute  list-group overflow-auto ${className ? className : ""}`}>
            {items.map((item: any, index: number) => {
                return item ? (
                    <button
                        key={index}
                        type="button"
                        className="list-group-item list-group-item-action"
                        onClick={() => {
                            onClickHandler(index);
                        }}
                    >
                        {item}
                    </button>
                ) : null;
            })}
        </div>
    );
};

export default AutoComplete;

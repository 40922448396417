import React from "react";
import { SzBox, SzTypographie } from "@suezenv/react-theme-components";
import signalService from "../../../../services/api/signal-service";
import { useTranslationWithPrefix } from "../../../../hooks/translation";
import Loader from "react-loader-spinner";
import FileImage from './fileImage'
import './pictures.scss';

export interface ISignalPicture {
    data: string; 
    fileName: string; 
    link: string; 
    extension: string;
}

interface ISignalPictures {
    pictures: ISignalPicture[];
    countAttachments: number;
}

const SignalPictures: React.FC<ISignalPictures> = ({ pictures , countAttachments}) => {
    const trans = useTranslationWithPrefix("signalement", "detail_signal");
          
    return (
        <SzBox className={`bg-white p-4 row`}>
            {
                countAttachments === 0 ? (
                    <div className="col-12 m-3">
                        <div className="row justify-content-center">
                            <div className="col-auto">
                                <SzTypographie variant="body" weight="medium" className="mb-1">
                                    {trans("no_pictures")}
                                </SzTypographie>
                            </div>
                        </div>
                    </div>
                ) : pictures.length > 0 ? (
                    pictures.map((picture: ISignalPicture, index: number) => (
                        <div key={`picture-${index}-${picture.fileName}`} className="col-auto mb-2 picture-download" id={`picture-${index}-${picture.fileName}`}>
                            {/* image */}
                            {picture.data && <img src={picture.data} className="img-fluid" alt={picture.fileName} onClick={() => signalService.downloadAttachment(picture.link).then((response: { data: string; }) => {
                                const link = document.createElement('a');
                                link.setAttribute('download', `${picture.fileName}.${picture.extension}`);
                                link.href = window.URL.createObjectURL(new Blob([response.data]));
                                link.target = "blank";
                                document.body.appendChild(link);
                                link.click();
                            })} />}
                            {/* file */}
                            {!picture.data && <FileImage picture={picture} />}
                            <span className="fw-normal">{`${picture.fileName}.${picture.extension}`}</span>
                        </div>
                    ))
            ) : (
                <div className="col-12 m-3">
                    <div className="row justify-content-center loader">
                        <Loader
                          type="Puff"
                          color="#9bcd41"
                          height={100}
                          width={100}
                          timeout={120000}
                        />
                    </div>
                </div>
            )}
        </SzBox>
    );
};
export default SignalPictures;

import { LatLngTuple } from "leaflet";
import React, { useEffect } from "react";
import { useMap } from "react-leaflet";

interface IMapViewChanger {
    zoom: number;
    center: LatLngTuple;
}
const MapViewChanger: React.FC<IMapViewChanger> = ({ center, zoom }) => {
    const map = useMap();
    useEffect(() => {
        map.setView(center, zoom);
    }, [center, zoom, map]);
    return null;
};

export default MapViewChanger;

import React, { useEffect, useMemo, useState } from "react";
import { Form, Formik } from "formik";
import { SzButton, SzIcon } from "@suezenv/react-theme-components";
import SzDatePickerWithLabel from "../../../form/SzDatePickerWithLabel/SzDatePickerWithLabel";
import { dateToString, stringToDate, FORMAT_DATE } from "../../../helpers/date.util";
import { useTranslationWithPrefix } from "../../../hooks/translation";
import SzMultipleCheckbox, { OptionItem } from "../../../form/SzMultipleCheckbox/SzMultipleCheckbox";
import "./signal-filter.form.scss";
import SzInputWithClear from "../../../form/SzInputWithClear/SzInputWithClear";
import { RequestTypeReference, WorkflowStatusReference } from "../../../type/organization.type";
import { ISignalFilter } from "../../../type/signal.type";
import AutoComplete from "../../../form/autocomplete/autocomplete";
import { GeoService } from "../../../services/geo-service";
import { AUTOCOMPLETE_THRESHOLD } from "../../../constants/autocomplete";
import { IAddress } from "../../../type/Address.type";
import { DEFAULT_STATUS_FILTER } from "../../../constants/filters";

interface Props {
    requestTypes: RequestTypeReference[];
    status: WorkflowStatusReference[];
    showForm?: boolean;
    appliedFilters: ISignalFilter;
    onSubmit: (values: ISignalFilter) => any;
    onClose?: () => void;
}

const SignalFilterForm: React.FC<Props> = (props) => {
    const { onClose, onSubmit, showForm = true, appliedFilters } = props;
    const trans = useTranslationWithPrefix("signalement", "form_filter");
    const [searchAutoComplete, setSearchAutoComplete] = useState<IAddress[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>("");

    const typesOptions: OptionItem[] = useMemo(() => {
        return props.requestTypes.map((state: RequestTypeReference) => {
            return { label: state.label, value: state.requestType };
        });
    }, [props.requestTypes]);

    const statusOptions = useMemo(() => {
        return props.status.map((state: WorkflowStatusReference) => {
            return { label: state.label, value: state.statusId };
        });
    }, [props.status]);

    const signalmentFilter: ISignalFilter = useMemo(() => {
        let fromOneYear = new Date().setFullYear(new Date().getFullYear() - 1);
        let inOneYear = new Date().setFullYear(new Date().getFullYear() + 1);

        return {
            requestTypes: ("requestTypes" in appliedFilters) ? appliedFilters.requestTypes : typesOptions,
            status: ("status" in appliedFilters) ? appliedFilters.status : statusOptions.filter((statusOption) =>
                DEFAULT_STATUS_FILTER.status.map((item) => item.value).includes(statusOption.value),
            ),
            startDate: ("startDate" in appliedFilters) ? appliedFilters.startDate : dateToString(new Date(fromOneYear), FORMAT_DATE),
            endDate: ("endDate" in appliedFilters) ? appliedFilters.endDate : dateToString(new Date(inOneYear), FORMAT_DATE),
            address: ("address" in appliedFilters) ? appliedFilters.address : undefined,
            instigators: [],
        };
    }, [appliedFilters, typesOptions, statusOptions]);

    useEffect(() => { // Set default address values from session
        setSearchTerm(appliedFilters?.address?.address ?? '')
    }, [appliedFilters?.address])

    const onSearchChange = (searchTerm: string): void => {
        setSearchTerm(searchTerm);
        if (searchTerm.length >= AUTOCOMPLETE_THRESHOLD) {
            GeoService.searchAddress(searchTerm).then((autoCompleteList: IAddress[]) => {
                setSearchAutoComplete(autoCompleteList);
            });
        } else {
            setSearchAutoComplete([]);
        }
    };

    return (
        <div className={`signalement-filter-form ${showForm ? "opened" : "closed"}`}>
            <Formik onSubmit={onSubmit} initialValues={signalmentFilter} enableReinitialize={true}>
                {({ handleSubmit, values, setFieldValue }) => {

                    const { startDate, endDate, requestTypes, status } = values;
                    const startDateDate = startDate ? stringToDate(startDate, FORMAT_DATE) : null;
                    const endDateDate = endDate ? stringToDate(endDate, FORMAT_DATE) : null;
                    const minDate = stringToDate("2000-01-01", FORMAT_DATE);
                    const maxDate = stringToDate("2099-12-31", FORMAT_DATE);
                    const onDateChange = (name: string, value?: Date | Date[]) => {
                        if (value && value instanceof Date) {
                            setFieldValue(name, dateToString(value as Date, FORMAT_DATE));
                        } else {
                            setFieldValue(name, null);
                        }
                    };

                    const autCompleteItemClicked = (index: number) => {
                        setSearchTerm(searchAutoComplete[index].address);
                        setSearchAutoComplete([]);
                        setFieldValue("address", searchAutoComplete[index]);
                    };

                    return (
                        <Form onSubmit={handleSubmit}>
                            <div className="text-right d-xl-none">
                                <SzIcon
                                    className="close-icon"
                                    icon="remove"
                                    variant="line"
                                    onClick={() => onClose?.()}
                                />
                            </div>
                            <h3 className="text-center text-black-50">{trans("title")}</h3>
                            <SzButton className="col-12 mr-3" type="submit" icon="filter-1">
                                {trans("filter")}
                            </SzButton>
                            <div className="d-flex">
                                <div className="col-12 px-0">
                                    <SzInputWithClear
                                        placeholder={trans("address_placeholder")}
                                        value={searchTerm}
                                        name="address"
                                        // tslint:disable-next-line: jsx-no-lambda
                                        onChange={(event) => onSearchChange(event.target.value)}
                                        icon="search"
                                        // tslint:disable-next-line: jsx-no-lambda
                                        onClear={() => {
                                            setSearchTerm("");
                                            setFieldValue("address", undefined);
                                            setSearchAutoComplete([]);
                                        }}
                                    />
                                    <AutoComplete
                                        items={searchAutoComplete.map((item: IAddress) => item.address)}
                                        onClickHandler={autCompleteItemClicked}
                                        className="top-50"
                                    />
                                </div>
                            </div>
                            <h4 className="pretty-label">{trans("date_label")}</h4>
                            <SzDatePickerWithLabel
                                onClear={() => setFieldValue("startDate", null)}
                                dateRange={false}
                                label={trans("start_date")}
                                startDate={startDateDate!}
                                onChangeStartDate={(value) => onDateChange("startDate", value)}
                                endMinDate={minDate}
                                startMinDate={minDate}
                                maxDate={maxDate}
                                maxEndDate={maxDate}
                            />
                            <SzDatePickerWithLabel
                                onClear={() => setFieldValue("endDate", null)}
                                dateRange={false}
                                label={trans("end_date")}
                                startDate={endDateDate!}
                                onChangeStartDate={(value) => onDateChange("endDate", value)}
                                endMinDate={minDate}
                                startMinDate={minDate}
                                maxDate={maxDate}
                                maxEndDate={maxDate}
                            />
                            <h4 className="pretty-label">{trans("state_label")}</h4>
                            <SzMultipleCheckbox
                                postRender={(option: OptionItem) => <span className={`dot ${option.value}`} />}
                                className="mt-3"
                                options={statusOptions}
                                name="status"
                                onChange={(name, values) => setFieldValue(name, values)}
                                values={status}
                            />
                            <h4 className="pretty-label">{trans("type_label")}</h4>
                            {requestTypes && (
                                <SzMultipleCheckbox
                                    className="mt-3"
                                    options={typesOptions}
                                    name="requestTypes"
                                    onChange={(name, values) => setFieldValue(name, values)}
                                    values={requestTypes}
                                />
                            )}
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

export default SignalFilterForm;

import React, { useEffect, useState } from "react";
import classnames from "classnames";
import AutoComplete from "../../../form/autocomplete/autocomplete";
import { SzBox, SzButton, SzIcon, SzInput } from "@suezenv/react-theme-components";
import "./map-search.scss";
import { IAddress } from "../../../type/Address.type";
import { useTranslationWithPrefix } from "../../../hooks/translation";
import { AUTOCOMPLETE_THRESHOLD } from "../../../constants/autocomplete";
import { GeoService } from "../../../services/geo-service";

interface IMapSearch {
    className?: string;
    address?: IAddress;
    setAddress: (address?: IAddress) => void;
}

const MapSearch: React.FC<IMapSearch> = ({ className, address, setAddress }) => {
    const trans = useTranslationWithPrefix("signalement", "address_search");
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [searchAutoComplete, setSearchAutoComplete] = useState<IAddress[]>([]);

    useEffect(() => {
        if (address) {
            setSearchTerm(address?.address);
        }
    }, [address]);

    const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const value = e.target.value;
        setSearchTerm(value);
        if (e.target.value.length >= AUTOCOMPLETE_THRESHOLD) {
            GeoService.searchAddress(value).then((autoCompleteList: IAddress[]) => {
                setSearchAutoComplete(autoCompleteList);
            });
        } else {
            setSearchAutoComplete([]);
        }
    };

    const onClearSearch = () => {
        setSearchTerm("");
        setSearchAutoComplete([]);
    };

    const autCompleteItemClicked = (index: number) => {
        setSearchTerm(searchAutoComplete[index].address);
        setSearchAutoComplete([]);
        searchAddress(searchAutoComplete[index].address);
    };

    const searchAddress = (address: string) => {
        GeoService.searchAddress(address).then((addresses: IAddress[]) => {
            if (addresses[0]) {
                setAddress(addresses[0]);
            } else {
                setAddress();
            }
        });
    };

    const myLocalisation = () => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(async (position) => {
                const addresses = await GeoService.getAddressFromLatLang(
                    position.coords.latitude,
                    position.coords.longitude,
                );
                if (addresses.length > 0) {
                    setAddress(addresses[0]);
                } else {
                    setAddress();
                }
            });
        }
    };

    return (
        <SzBox tag="div" className={classnames("map-search-container", "px-0 px-md-5 py-2", className)}>
            <div className="d-flex justify-content-around">
                <div className="justify-content-start d-none d-md-inline-block my-auto"> {trans("label")}</div>
                <div className="w-100 w-50-m">
                    <div className="d-flex justify-content-lg-end justify-content-center">
                        <div className="col-8 offset-md-2 p-2">
                            <SzInput
                                className="mb-0"
                                icon="search"
                                onChange={onSearchChange}
                                name="search"
                                type="text"
                                value={searchTerm}
                                placeholder={trans("placeholder")}
                            />
                            {searchAutoComplete.length > 0 && (
                                <AutoComplete
                                    items={searchAutoComplete.map((item: IAddress) => item.address)}
                                    onClickHandler={autCompleteItemClicked}
                                    className="w-100 pr-3"
                                />
                            )}
                            {searchTerm && (
                                <span className="clear-search-btn  mb-2 d-flex align-items-center justify-content-center cursor-pointer">
                                    <SzIcon icon="remove" onClick={onClearSearch} variant="line" />
                                </span>
                            )}
                        </div>

                        <SzButton className="mb-0 ml-1 mt-2" onClick={myLocalisation} icon="pin-location-1" />
                    </div>
                </div>
            </div>
        </SzBox>
    );
};

export default MapSearch;

import React from "react";

const CreateSignalSvg: React.FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 76 96">
            <path
                d="M61.5 54.2c.7-1.1.7-2.5.1-3.7L41.4 12c-.7-1.2-1.9-2-3.4-2-1.4 0-2.7.8-3.4 2L14.4 50.6c-.6 1.2-.6 2.5.1 3.7.7 1.1 1.9 1.8 3.2 1.8h40.4c1.5-.1 2.7-.8 3.4-1.9zM34 24c0-2.2 1.8-4 4-4s4 1.8 4 4v12c0 2.2-1.8 4-4 4s-4-1.8-4-4V24zm4 28c-2.8 0-5-2.2-5-5s2.2-5 5-5 5 2.2 5 5-2.2 5-5 5z"
                fillRule="evenodd"
                clipRule="evenodd"
                fill="#fff"
            />
            <path
                d="M38 20c2.2 0 4 1.8 4 4v12c0 2.2-1.8 4-4 4s-4-1.8-4-4V24c0-2.2 1.8-4 4-4zm0 22c2.8 0 5 2.2 5 5s-2.2 5-5 5-5-2.2-5-5 2.3-5 5-5zm0-32c-1.4 0-2.7.8-3.4 2L14.4 50.6c-.6 1.2-.6 2.5.1 3.7.7 1.1 1.9 1.8 3.2 1.8h40.4c1.3 0 2.5-.7 3.2-1.8.7-1.1.7-2.5.1-3.7L41.4 12c-.7-1.2-2-2-3.4-2zm0-10c17.8 0 33.3 12.4 37.1 29.8C79 47.2 70.2 65 54 72.5c-2.1 1-3.9 2.7-4.9 4.9l-7.5 16.3c-.6 1.4-2 2.3-3.6 2.3s-3-.9-3.6-2.3l-7.5-16.3c-1-2.2-2.7-3.9-4.9-4.9C5.8 65-2.9 47.2.9 29.8S20.2 0 38 0z"
                fillRule="evenodd"
                clipRule="evenodd"
                fill="#1b1d3b"
            />
        </svg>
    );
};

export default CreateSignalSvg;

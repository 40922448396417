import { Request } from "../Request";
import instance from "../AppRequest";
import { AxiosResponse } from "axios";
import { WorkflowStatusReference } from "../../type/organization.type";

class WorkflowStatusReferenceRepository {
    private axiosService: Request;

    /**
     * @param request
     */
    constructor(request: any) {
        this.axiosService = request;
    }

    getAll(organizationId: string): Promise<AxiosResponse<WorkflowStatusReference[]>> {
        return this.axiosService.get(`/api/organization/${organizationId}/status`);
    }
}

export default new WorkflowStatusReferenceRepository(instance);

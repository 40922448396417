import { useMemo } from "react";
import { UserType } from "../type/user.type";
import { SERVICES_LIST, SIGNAL_OPERATOR } from "../constants/services";
export const useIsHasSignalService = (user: UserType) => {
    const isHasSignalService = useMemo(() => {
        if (user.data) {
            for (let i = 0; i < SERVICES_LIST.length; i++) {
                const isHasCurrentService = Object.keys(user.data.services).includes(SERVICES_LIST[i]);
                if (isHasCurrentService) {
                    return true;
                }
            }
            return false;
        }
        return false;
    }, [user]);
    return isHasSignalService;
};

export const useIsHasOperatorService = (user: UserType) => {
    const isHasSignalService = useMemo(() => {
        if (user.data) {
            return Object.keys(user.data.services).includes(SIGNAL_OPERATOR);
        }
        return false;
    }, [user]);
    return isHasSignalService;
};

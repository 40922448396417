import React, { useEffect, useState } from "react";
import { SzButton } from "@suezenv/react-theme-components";
import Form, { FormProps, AjvError } from "@rjsf/core";
import SzInputForm from "../../../../../form/json-form/field/SzInputForm";
import NullForm from "../../../../../form/json-form/field/NullForm";
import FileInputDragAndDrop from "../../../../../form/json-form/field/FileInputDragAndDrop";
import SzTextareaForm from "../../../../../form/json-form/field/SzTextareaForm";
import ObjectFieldTemplateStepper from "../object-field-template-stepper";
import SzRadioButtonForm from "../../../../../form/json-form/field/SzRadio-button.form";
import CustomErrorTransformer from "./custom-error-transfromer";
import { useTranslationWithPrefix } from "../../../../../hooks/translation";
import { ISubmitContext } from "../context/submit-context";
import _ from "lodash";
interface Props {
    defaultData?: any;
    cancel: () => void;
    onSubmit: FormProps<any>["onSubmit"];
    uiSchema: FormProps<any>["uiSchema"] & any;
    schema: FormProps<any>["schema"] & any;
    onChange?: FormProps<any>["onChange"];
    widgets?: any;
    formProps?: Partial<FormProps<any>>;
    children?: any;
}

const BaseForm: React.FC<Props> = ({
    defaultData = {},
    cancel,
    onSubmit,
    onChange,
    schema,
    uiSchema,
    children,
    formProps,
    ...rest
}) => {
    const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
    const [submitVisible, setSubmitVisible] = useState<boolean>(true);
    const [invalidProperties, setInvalidProperties] = useState<string[]>([]);
    const [currentData, setCurrentData] = useState<any>(defaultData);
    const errorsTrans = useTranslationWithPrefix("create_signalement", "errors");
    const stepperTrans = useTranslationWithPrefix("create_signalement", "stepper");
    useEffect(() => {
        setCurrentData(defaultData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * todo add here other inputs.
     */
    const widgets: any = rest.widgets
        ? rest.widgets
        : {
              TextWidget: SzInputForm,
              TitleField: NullForm,
              SelectWidget: SzRadioButtonForm,
              FileWidget: FileInputDragAndDrop,
              TextareaWidget: SzTextareaForm,
              radioWidget: SzRadioButtonForm,
          };

    // : FormProps<any>['onChange']
    const onFormChange = (e: any) => {
        setSubmitDisabled(e.errors.length > 0);
        setCurrentData(e.formData);
        onChange?.(e);
    };
    const errorTransformer = (errors: AjvError[]): AjvError[] => {
        return CustomErrorTransformer(errors, errorsTrans);
    };
    const onError = (errors: AjvError[]) => {
        const propertiesWithErrors = errors.map((error: AjvError) => error.property);
        const isFormErrorChanged = _.xorWith(propertiesWithErrors, invalidProperties, _.isEqual).length !== 0;
        if (isFormErrorChanged) {
            setInvalidProperties([...propertiesWithErrors]);
        }
    };
    return (
        <ISubmitContext.Provider
            value={{
                submitEnabled: submitVisible,
                setSubmitEnabled: setSubmitVisible,
                invalidProperties: invalidProperties,
            }}
        >
            <Form
                ref={(formRef) => {
                    const state: any = formRef?.state as any;
                    if (formRef && state.errors) {
                        onError(state.errors);
                    }
                }}
                className="w-100 base-form"
                idPrefix="sz-form"
                liveValidate={true}
                showErrorList={false}
                formData={currentData}
                onChange={onFormChange}
                onSubmit={onSubmit}
                uiSchema={uiSchema}
                schema={schema}
                widgets={widgets}
                ObjectFieldTemplate={ObjectFieldTemplateStepper}
                transformErrors={errorTransformer}
                {...formProps}
            >
                {children ? children : null}
                {!children && (
                    <div className="row justify-content-center justify-content-between">
                        <div className="col-auto">
                            <SzButton variant="secondary" onClick={cancel}>
                                {stepperTrans("cancel")}
                            </SzButton>
                        </div>
                        {submitVisible && (
                            <div className="col-auto">
                                <SzButton type="submit" isDisabled={submitDisabled} icon="send-email-1">
                                    {stepperTrans("send")}
                                </SzButton>
                            </div>
                        )}
                    </div>
                )}
            </Form>
        </ISubmitContext.Provider>
    );
};

export default BaseForm;

import React, { useContext, useMemo, useRef, useState } from "react";
import { DEFAULT_ZOOM, STREET_ZOOM, MAP_CENTER } from "../../constants/map";
import MapSearch from "./map-search/map-search";
import "./signal-create-address.scss";
import { Marker, useMapEvents } from "react-leaflet";
import MapViewChanger from "./map-view-changer/map-view-changer";
import { SzBox, SzButton, SzIcon, SzModal, SzTypographie } from "@suezenv/react-theme-components";
import { IAddress } from "../../type/Address.type";
import { useTranslationWithPrefix } from "../../hooks/translation";
import SignalContext from "../../context/signal.context";
import { GeoService } from "../../services/geo-service";
import SignalMap from "../signal-view/map/signal-map";
import { createSignalPin } from "./icons/create-signal-pin";

interface ISignalCreateAddress {
    validateAddress: (address: IAddress) => void;
    geoShape?: GeoJSON.Feature;
}

const SignalCreateAddress: React.FC<ISignalCreateAddress> = ({ validateAddress, geoShape }): JSX.Element => {
    const signalContext = useContext(SignalContext);
    const [showModal, setShowModal] = useState(false);
    const [addressInfo, setAddressInfo] = useState<IAddress>();
    const trans = useTranslationWithPrefix("signalement", "create_signal");
    const markerRef = useRef(null);
    const eventHandlers = useMemo(
        () => ({
            dragend() {
                const marker: any = markerRef.current;
                if (marker) {
                    const { lat, lng } = marker.getLatLng();
                    GeoService.getAddressFromLatLang(lat, lng).then((addresses: IAddress[]): void => {
                        if (addresses.length > 0 && addressInfo) {
                            const updatedAddress: IAddress = {
                                ...addresses[0],
                            };
                            setAddressInfo(updatedAddress);
                        }
                    });
                }
            },
        }),
        [addressInfo],
    );

    const handleValidateClick = () => {
        if (addressInfo && signalContext.geoServer) {
            GeoService.isPointInDepartement(signalContext.geoServer, [
                addressInfo.latitude,
                addressInfo.longitude,
            ]).then((isInDepartement: boolean) => {
                if (isInDepartement) {
                    validateAddress(addressInfo);
                } else {
                    setShowModal(true);
                }
            });
        }
    };

    const LocationMarker = () => {
      const map = useMapEvents({
        click(e) {
          GeoService.getAddressFromLatLang(e.latlng.lat, e.latlng.lng).then((addresses: IAddress[]): void => {
            if (addresses.length > 0) {
              const updatedAddress: IAddress = {
                ...addresses[0],
              };
              map.flyTo(e.latlng, map.getZoom())
              setAddressInfo(updatedAddress);
            }
          });
        }
      })
        return addressInfo ? <Marker
          ref={markerRef}
          draggable={true}
          eventHandlers={eventHandlers}
          position={[addressInfo.latitude, addressInfo.longitude]}
          icon={createSignalPin}
        /> : <React.Fragment></React.Fragment>;
    }

    window.dispatchEvent(new Event('resize'));

    return (
        <div className="signal-create signal-map">
            <MapSearch className="signal-create-search" address={addressInfo} setAddress={setAddressInfo} />
            <SignalMap
              defaultZoom={DEFAULT_ZOOM}
              center={MAP_CENTER}
              geoShape={geoShape}
            >
              {addressInfo && <MapViewChanger zoom={STREET_ZOOM} center={[addressInfo.latitude, addressInfo.longitude]} />}
              <LocationMarker />
            </SignalMap>
            {addressInfo && (
                <SzBox className="validate position-absolute row justify-content-center align-items-center" tag="div">
                    <div className="col-auto">
                        <div className="row justify-content-center">
                            <SzTypographie
                                weight="light"
                                variant="caption"
                                className="address-caption bg-white mb-1 py-1 px-2"
                            >
                                {addressInfo?.address}
                            </SzTypographie>
                        </div>
                        <SzButton className="mb-0" onClick={handleValidateClick} icon="pin-location-1">
                            {trans("validation_btn")}
                        </SzButton>
                    </div>
                </SzBox>
            )}
            <SzModal
                title={trans("out_of_zone_title")}
                show={showModal}
                // tslint:disable-next-line: jsx-no-lambda
                handleClose={() => setShowModal(false)}
                size={"sm"}
                centered={true}
                hideCloseBtn={false}
                backdrop={true}
            >
                <div className="row justify-content-center">
                    <div className="col-auto">
                        <SzIcon className="alert-icon" variant="bold" icon="alert-triangle" />
                    </div>
                </div>
                <SzTypographie variant="caption" weight="medium" align="center" className="mb-3">
                    {trans("out_of_zone_message")}
                </SzTypographie>
                <div className="row justify-content-center">
                    <div className="col-auto">
                        <SzButton className="mb-0" onClick={(): void => setShowModal(false)}>
                            {trans("leave_btn")}
                        </SzButton>
                    </div>
                </div>
            </SzModal>
        </div>
    );
};

export default SignalCreateAddress;

import React, { useMemo } from "react";
import { SzBox, SzButton, SzIcon, SzTypographie } from "@suezenv/react-theme-components";
import { dateToString, stringToDate } from "../../../../helpers/date.util";
import useReplaceNull from "../../../../hooks/replace-null";
import { useTranslationWithPrefix } from "../../../../hooks/translation";
import { SignalRequest, SignalStatus } from "../../../../type/signal.type";

interface ISignalComments {
    signal: SignalRequest;
    className?: string;
    addComment: () => void;
}
const SignalComments: React.FC<ISignalComments> = ({ signal, className, addComment }) => {
    const trans = useTranslationWithPrefix("signalement", "detail_signal");
    const replaceNull = useReplaceNull("-");
    const statusWithComments = useMemo<SignalStatus[]>(() => {
        if (signal.statuses) {
            return signal.statuses.filter((status: SignalStatus) => Boolean(status.comment));
        }
        return [];
    }, [signal.statuses]);
    return (
        <SzBox className={`bg-white p-4 ${className}`}>
            {statusWithComments.length > 0 ? (
                statusWithComments.map(
                    (status: SignalStatus, index: number) =>
                        status.comment && (
                            <div key={index} className="row mb-2">
                                <div className="col-auto">
                                    <SzIcon variant="line" icon="people-man-1" />
                                </div>
                                <div className="col">
                                    <SzTypographie variant="caption" weight="light" className="">
                                        {`${dateToString(stringToDate(status.createdAt), "DD/MM/YYYY")} |
                                            ${status.status.label}`}
                                    </SzTypographie>
                                    <SzTypographie variant="body" weight="medium" className="mb-1">
                                        {status.applicationUser.fullName}
                                    </SzTypographie>
                                    <SzTypographie variant="body">{replaceNull(status.comment)}</SzTypographie>
                                </div>
                            </div>
                        ),
                )
            ) : (
                <div className="col-12 m-3">
                    <div className="row justify-content-center">
                        <div className="col-auto">
                            <SzTypographie variant="body" weight="medium" className="mb-1">
                                {trans("no_comments")}
                            </SzTypographie>
                        </div>
                    </div>
                </div>
            )}

            <div className="row justify-content-end add-comment">
                <div className="col-auto">
                    <SzButton
                        variant="secondary"
                        className="mr-3"
                        icon="messages-bubble-edit"
                        alignIcon="right"
                        onClick={addComment}
                    >
                        {trans("comment_btn")}
                    </SzButton>
                </div>
            </div>
        </SzBox>
    );
};
export default SignalComments;

import React, { useContext } from "react";
import { Marker, Tooltip } from "react-leaflet";
import SignalDivIconGenerator from "../../../helpers/icon.util";
import { IPin } from "../../../type/resource.type";
import SignalContext from "../../../context/signal.context";
import MapTooltip from "./map-tooltip";
import { LatLngExpression } from "leaflet";

interface ISignalTypeMarker {
    signalId: string;
    currentStatusId: string;
    currentStatusLabel: string;
    fullAddress: string;
    fullName: string;
    coordinate: LatLngExpression;
    pin?: IPin;
}
const SignalTypeMarker: React.FC<ISignalTypeMarker> = ({ signalId, coordinate, pin, currentStatusId, ...rest }) => {
    const signalContext = useContext(SignalContext);
    return (
        <Marker
            key={signalId}
            position={[coordinate[0], coordinate[1]]}
            icon={new SignalDivIconGenerator(currentStatusId).generateDivIcon(pin?.data)}
            eventHandlers={{
                click: (e) => {
                    if (signalContext.goSignalDetail) {
                        signalContext.goSignalDetail(signalId);
                    }
                },
            }}
        >
            <Tooltip direction="top" offset={[0, -30]} opacity={1}>
                <MapTooltip {...rest} />
            </Tooltip>
        </Marker>
    );
};

export default SignalTypeMarker;

import React, { useEffect, useState } from "react";
import { CITY_ZOOM, DEFAULT_ZOOM, MAP_CENTER, STREET_ZOOM } from "../../constants/map";
import { SzBox, SzButton, SzIcon, SzTab, SzTypographie } from "@suezenv/react-theme-components";
import SzLoadSpinner from "../utils";
import {
    IPagination,
    ISignalStatus,
    SignalGeoJSONProperties,
    SignalResponseFormat,
    SignalType,
    IStatusStats,
    ISort,
} from "../../type/signal.type";
import { useTranslationWithPrefix } from "../../hooks/translation";
import SignalContext from "../../context/signal.context";
import SignalMap from "./map/signal-map";
import SignalList from "./list/signal-list";
import "./signal-view.scss";
import SignalFilterForm from "./filter-form/signal-filter.form";
import { SignalRequest } from "../../type/signal.type";
import requestTypeReferenceRepository from "../../services/repository/request-type-reference.repository";
import { RequestTypeReference, WorkflowStatusReference } from "../../type/organization.type";
import { ISignalFilter } from "../../type/signal.type";
import { useOrganization } from "../../hooks/organization";
import workflowStatusReferenceRepository from "../../services/repository/status-reference.repository";
import signalService from "../../services/api/signal-service";
import { OptionItem } from "../../form/SzMultipleCheckbox/SzMultipleCheckbox";
import { UserType } from "../../type/user.type";
import { useIsHasSignalService } from "../../hooks/service";
import Unauthorized from "../message/unauthorized";
import { DEFAULT_STATUS_FILTER, PAGINATION_NUMBER_PER_PAGE, SORT_FIELD_DEFAULT, SORT_ORDER_DEFAULT } from "../../constants/filters";
import { AxiosResponse } from "axios";
import i18n from "../../../i18n";
import { I18nextProvider } from "react-i18next";
import MapViewChanger from "../signal-create/map-view-changer/map-view-changer";

interface ISignalView {
    geoServer?: {
        url: string;
        workspace: string;
        layer: string;
        authkey: string;
    };
    user: UserType;
    addSignal: () => void;
    goSignalDetail: (signalId: string) => void;
    signalType: SignalType;
    enableBatchExport?: boolean
    geoShape?: GeoJSON.Feature;
}

export const SignalView: React.FC<ISignalView> = ({
    geoServer,
    user,
    addSignal,
    goSignalDetail,
    signalType,
    enableBatchExport,
    geoShape
}) => {
    const TAB_MAP = 'tab-map'
    const TAB_LIST = 'tab-list'
    const SIGNAL_TYPE_MAP = 'map'
    const SIGNAL_TYPE_LIST = 'list'
    const SESSION_FILTERS_KEY = 'appliedFilters'

    const [signalList, setSignalList] = useState<{
        geojsonData?: GeoJSON.FeatureCollection<GeoJSON.Point, SignalGeoJSONProperties>;
        signals?: SignalRequest[];
        pagination: IPagination;
        sort: ISort;
    }>();
    const [showFilter, setShowFilter] = useState<boolean>(false);
    const [showFilterLoader, setShowFilterLoader] = useState<boolean>(false);
    const [appliedFilter, setAppliedFilter] = useState<ISignalFilter>(DEFAULT_STATUS_FILTER);
    const [requestTypes, setRequestTypes] = useState<RequestTypeReference[]>([]);
    const [workflowStatus, setWorkflowStatus] = useState<WorkflowStatusReference[]>([]);
    const [statusStats, setStatusStats] = useState<ISignalStatus>();
    const [zoom, setZoom] = useState<number>(DEFAULT_ZOOM);
    const [signalTypeChange, setSignalTypeChange] = useState<string>(signalType)
    const organization = useOrganization(user);
    const isHasSignalService = useIsHasSignalService(user);
    const [SignalTab, setSignalTab] = useState(signalType === SIGNAL_TYPE_LIST ? TAB_LIST : TAB_MAP);
    const [loader, setLoader] = useState(true);
    const trans = useTranslationWithPrefix("signalement", "view_signal");

    const selectSignalTabHandle = (tab: string) => {
        setLoader(true)
        setSignalTab(tab)
        setSignalTypeChange(tab === TAB_LIST ? SIGNAL_TYPE_LIST : SIGNAL_TYPE_MAP);
    };
    const EnableShowFilterLoader = (filters: ISignalFilter) => {
        if (Object.keys(filters).includes("instigators")) {
            setShowFilterLoader(true);
        }
    }
    const getSignals = (filters?: ISignalFilter, currentPage?: number) => {
        if (sessionStorage.getItem(SESSION_FILTERS_KEY)) {
            let sessionFilters = JSON.parse(sessionStorage.getItem(SESSION_FILTERS_KEY) as string)
            sessionStorage.removeItem(SESSION_FILTERS_KEY)
            applyFilter(sessionFilters, currentPage);
        } else if (filters) {
            applyFilter(filters, currentPage);
        } else {
            applyFilter(appliedFilter, currentPage);
        }
    };

    useEffect(() => {
        if (loader) {
            getSignals();
        }
    }, [loader]);

    useEffect(() => {
        let isMounted = true;
        if (organization) {
            requestTypeReferenceRepository.getAll(organization.id).then(({ data }) => {
                if (isMounted) {
                    setRequestTypes(data);
                }
            });
            workflowStatusReferenceRepository.getAll(organization.id).then(({ data }) => {
                if (isMounted) {
                    setWorkflowStatus(data);
                }
            });
        }

        // Clean-up:
        return () => {
            isMounted = false;
        }

    }, [organization]);

    const applyFilter = (filters: ISignalFilter, currentPage?: number) => {
        const queryParams: any = {
            pagination: {
                size: PAGINATION_NUMBER_PER_PAGE,
            },
            sort: {
                fields: [SORT_FIELD_DEFAULT],
                order: [SORT_ORDER_DEFAULT],
            },
        };

        if (filters.address) {
            if ('municipality' === filters.address.type) {
                queryParams.filterFields = [`address.city__EQ__[${filters.address.city}]`];
                setZoom(CITY_ZOOM);
            } else {
                queryParams.distance = {
                    length: "500",
                    latitude: filters.address.latitude,
                    longitude: filters.address.longitude,
                };
                setZoom(STREET_ZOOM);
            }
        }

        if (currentPage) {
            queryParams["pagination"]["page"] = currentPage;
        }
        let format: SignalResponseFormat = "json";

        if (signalTypeChange === SIGNAL_TYPE_MAP) {
            format = "geojson";
            queryParams["exportFields"] = [
                "id",
                "currentStatus.status.id",
                "currentStatus.status.label",
                "address.address",
                "applicationUser.fullName",
                "requestType",
            ];
            queryParams["exportLabels"] = [
                "id",
                "currentStatusId",
                "currentStatusLabel",
                "fullAddress",
                "fullName",
                "requestType",
            ];
        }
        // display Loader when Filter submit button is pressed
        EnableShowFilterLoader(filters);
        queryParams.filter = parseFilter(filters);

        signalService.getAll(queryParams, format).then(({ data, headers }) => {
            setAppliedFilter(filters);
            setSignalList({
                geojsonData:
                    signalTypeChange === SIGNAL_TYPE_MAP
                        ? (data as GeoJSON.FeatureCollection<GeoJSON.Point, SignalGeoJSONProperties>)
                        : undefined,
                signals: signalTypeChange === SIGNAL_TYPE_LIST ? (data as SignalRequest[]) : undefined,
                pagination: {
                    totalCount: headers["x-pagination-total-count"],
                    pageCount: headers["x-pagination-page-count"],
                    currentPage: headers["x-pagination-current-page"],
                    perPage: headers["x-pagination-per-page"],
                },
                sort: {
                    field: SORT_FIELD_DEFAULT,
                    order: SORT_ORDER_DEFAULT,
                }
            });

            if (data) {
                setLoader(false)
                setShowFilterLoader(false)
            }

            sessionStorage.setItem(SESSION_FILTERS_KEY, JSON.stringify(filters))
        })
    };

    const parseFilter = (filters: ISignalFilter) => {
        const filter: any = {
            createdAt: {},
        };
        if (filters.requestTypes) {
            filter.requestType = filters.requestTypes.map((requestType: OptionItem) => requestType.value);
        }
        if (filters.status) {
            filter.status = filters.status.map((status: OptionItem) => status.value);
        }
        if (filters.startDate) {
            filter.createdAt.start = filters.startDate;
        }
        if (filters.endDate) {
            filter.createdAt.end = filters.endDate + 'T23:59:59Z';
        }
        return filter;
    };

    useEffect(() => {
        let isMounted = true;
        if (organization) {
            const queryParams: any = {};
            if (appliedFilter.address) {

                if (appliedFilter.address.address === appliedFilter.address.city) {
                    queryParams.filterFields = [`address.city__EQ__[${appliedFilter.address.city}]`];
                } else {
                    queryParams.distance = {
                        length: "500",
                        latitude: appliedFilter.address.latitude,
                        longitude: appliedFilter.address.longitude,
                    };
                }
            }
            queryParams.filter = parseFilter(appliedFilter);
            signalService.getStatusStats(queryParams).then((response: AxiosResponse<IStatusStats>) => {
                if (isMounted) {
                    setStatusStats(response.data?.cumulated);
                }
            })

        }
        // Clean-up:
        return () => {
            isMounted = false;
        }

    }, [organization, appliedFilter]);

    return (
        <I18nextProvider i18n={i18n}>
            {isHasSignalService ? (
                <SignalContext.Provider
                    value={{
                        geoServer,
                        goSignalDetail,
                        enableBatchExport,
                    }}
                >
                    <div className='row-fluid spinner-container'>
                        <SzLoadSpinner loader={loader || showFilterLoader} variant='info' />
                    </div>
                    <div className="signal-view">
                        <div className="signal-view-container">
                            <div className='mr-3 ml-3 mt-3 mb-0 p-2 pl-0 signal-header border-left'>
                                <div className="row-fluid">
                                    <div className='col-5'>
                                        <SzTypographie variant="h1" className='m-0 request-primary-text'>
                                            {trans('signal_header_title')}
                                        </SzTypographie>
                                        <SzTypographie className='m-0' color="inactive">
                                            {trans('signal_header_sub_title')}
                                        </SzTypographie>
                                    </div>
                                </div>
                            </div>
                            <SzTab
                                activeKey={SignalTab}
                                id="signal-tab"
                                selectTabHandle={selectSignalTabHandle}
                            >
                                <SzTab.SzTabContent eventKey={TAB_MAP} title={trans('tab_map_title')}>
                                    <div className="container-fluid">
                                        <div className="row-fluid">
                                            <div className='col-12  p-2 signal-map'>
                                                {signalTypeChange === SIGNAL_TYPE_MAP && signalList?.geojsonData && (
                                                    <SignalMap
                                                        requestTypes={requestTypes}
                                                        defaultZoom={zoom}
                                                        center={MAP_CENTER}
                                                        geojsonData={signalList?.geojsonData}
                                                        geoShape={geoShape}
                                                    >
                                                        {appliedFilter?.address?.address && <MapViewChanger zoom={zoom} center={[appliedFilter?.address?.latitude, appliedFilter?.address?.longitude]} />}
                                                    </SignalMap>

                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </SzTab.SzTabContent>

                                <SzTab.SzTabContent eventKey={TAB_LIST} title={trans('tab_list_title')}>
                                    <div className='mt-3 mb-0 pl-0'>
                                        <div className="row-fluid">
                                            <div className='col-12'>
                                                {signalTypeChange === SIGNAL_TYPE_LIST && signalList?.signals && (
                                                    <SignalList
                                                        signals={signalList.signals}
                                                        catergories={requestTypes}
                                                        statusStats={statusStats}
                                                        getSignals={(currentPage: number) => getSignals(undefined, currentPage)}
                                                        user={user}
                                                        workflowStatus={workflowStatus}
                                                        pagination={signalList?.pagination}
                                                        sort={signalList?.sort}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </SzTab.SzTabContent>

                            </SzTab>
                            <SzIcon
                                className="show-filter"
                                icon="search"
                                variant="line"
                                onClick={() => setShowFilter(true)}
                            />
                            <SignalFilterForm
                                requestTypes={requestTypes}
                                status={workflowStatus}
                                showForm={showFilter}
                                appliedFilters={appliedFilter}
                                onSubmit={applyFilter}
                                // tslint:disable-next-line: jsx-no-lambda
                                onClose={() => setShowFilter(false)}
                            />
                        </div>
                        {SignalTab === TAB_MAP && (<SzBox className="signal-btn w-50" tag="div">
                            <SzButton className="mb-0" onClick={addSignal} icon="add-circle">
                                {trans("add_signal")}
                            </SzButton>
                        </SzBox>)}
                    </div>
                </SignalContext.Provider>
            ) : (
                <Unauthorized />
            )}
        </I18nextProvider>
    );
};

import { FormProps } from "@rjsf/core";
import React, { useState } from "react";
import { I18nextProvider } from "react-i18next";
import { SzBox } from "@suezenv/react-theme-components";
import i18n from "../../../i18n";
import SignalContext from "../../context/signal.context";
import { useOrganization } from "../../hooks/organization";
import { useIsHasSignalService } from "../../hooks/service";
import signalService from "../../services/api/signal-service";
import { IAddress } from "../../type/Address.type";
import { RequestTypeReference } from "../../type/organization.type";
import { UserType } from "../../type/user.type";
import Unauthorized from "../message/unauthorized";
import SignalCreateAddress from "./signal-create-address";
import FirstStep from "./steps/first-step";
import RequestTypeDynamicForm from "./steps/second-step/form/requestType-dynamic.form";

type displayedComponentType = "address" | "choicesfirstStep" | "forms";

export interface ISignalCreate {
    geoServer?: {
        url: string;
        workspace: string;
        layer: string;
        authkey: string;
    };
    user: UserType;
    onSignalCreated: () => void;
    geoShape?: GeoJSON.Feature;
}

export const SignalCreate: React.FC<ISignalCreate> = ({ geoServer, geoShape, user, onSignalCreated }) => {
    const [displayedComponent, setDisplayedComponent] = useState<displayedComponentType>("address");
    const [selectedRequestType, setSelectedRequestType] = useState<RequestTypeReference>();
    const [selectedAddress, setSelectedAddress] = useState<IAddress>();
    const organization = useOrganization(user);
    const isHasSignalService = useIsHasSignalService(user);
    const validateAddress = (address: IAddress) => {
        setDisplayedComponent("choicesfirstStep");
        setSelectedAddress(address);
    };

    const onRequestTypeSelected = (requestType: RequestTypeReference) => {
        setSelectedRequestType(requestType);
        setDisplayedComponent("forms");
    };

    const onSubmitForm = (form: FormProps<any>) => {
        const comment = form.formData.comment;
        const pictures = form.formData.picture;
        let data = {};
        delete form.formData.comment;
        delete form.formData.picture;
        const properties = form.schema.properties;
        if (organization && selectedRequestType && selectedAddress) {
            Object.keys(form.formData).map((datakey: string) => {
                const dataId = form.formData[datakey];
                if (
                    properties &&
                    Object.keys(properties[datakey]).includes("enum") &&
                    Object.keys(properties[datakey]).includes("labels")
                ) {
                    const indexOfData = properties[datakey]["enum"].indexOf(dataId);
                    data[datakey] = {
                        id: dataId,
                        label: properties[datakey]["labels"][indexOfData],
                    };
                } else {
                    data[datakey] = form.formData[datakey];
                }
            });
            signalService
                .createSignal(organization?.id, selectedRequestType.id, data, selectedAddress, comment)
                .then((response: any) => {
                    signalService.addAttachments(response.headers["location"], pictures);
                })
                .then(() => onSignalCreated());
        }
    };
    const signalCreationSteps = {
        address: {
            component: <SignalCreateAddress validateAddress={validateAddress} geoShape={geoShape} />,
        },
        choicesfirstStep: {
            component: <FirstStep onSelect={onRequestTypeSelected} />,
        },
        forms: {
            component: selectedRequestType && (
                <RequestTypeDynamicForm
                    requestType={selectedRequestType}
                    onSubmitForm={onSubmitForm}
                    goBack={() => setDisplayedComponent("choicesfirstStep")}
                />
            ),
        },
    };

    return (
        <I18nextProvider i18n={i18n}>
            {isHasSignalService ? (
                <SignalContext.Provider
                    value={{
                        geoServer,
                        user,
                    }}
                >
                    <SzBox className="w-100 h-100 overflow-auto" tag="div">
                        {signalCreationSteps[displayedComponent].component}
                    </SzBox>
                </SignalContext.Provider>
            ) : (
                <Unauthorized />
            )}
        </I18nextProvider>
    );
};

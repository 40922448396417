declare global {
  interface Window {
    REACT_APP_API_HOST: any;
    REACT_APP_API_SIGNAL_HOST: any;
    REACT_APP_GEOSERVER_PROPERTY_NAME: any;
    REACT_APP_TOKEN_KEY: any;
    REACT_APP_ZEUS_CONTRACT: any;
  }
}

const apiUrl = window.REACT_APP_API_SIGNAL_HOST || process.env.REACT_APP_API_SIGNAL_HOST || window.REACT_APP_API_HOST || process.env.REACT_APP_API_HOST;
const geoserverPropertyName = window.REACT_APP_GEOSERVER_PROPERTY_NAME || process.env.REACT_APP_GEOSERVER_PROPERTY_NAME || '';
const tokenKey = window.REACT_APP_TOKEN_KEY || process.env.REACT_APP_TOKEN_KEY || '';
const zeusContract =  window.REACT_APP_ZEUS_CONTRACT || process.env.REACT_APP_ZEUS_CONTRACT;

// use process.env in dev instead window
export const config = {
  apiUrl: apiUrl,
  geoserverPropertyName: geoserverPropertyName,
  tokenKey: tokenKey,
  zeusContract: zeusContract
};

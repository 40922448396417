import React from "react";
import { FieldProps } from "react-jsonschema-form";
import FileUploader, { FileUploaderProps } from "../../../components/file-uploader/file-uploader";

interface FileInputDragAndDropProps extends FieldProps {
    options: FileUploaderProps;
}

// Must be in sync with backend upload
const fileWhitelist = [
    'image/*', 
    'application/msword',
    'application/pdf',  
    'application/vnd.ms-excel',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation', 
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'text/csv',
];

const toBase64 = (file: File): Promise<string | null | ArrayBuffer> =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const FileInputDragAndDrop: React.FC<FileInputDragAndDropProps> = (props) => {
    const change = async (files: File[]) => {
        Promise.all(
            files.map<Promise<any>>(async (file) => {
                return await toBase64(file);
            }),
        ).then((filesString: any[]) => {
            props.onChange(filesString);
        });
    };

    return (
        <FileUploader
            {...props.options}
            canDeleteFile={true}
            allowedExtensions={fileWhitelist}
            onChange={change}
            className="p-5"
        />
    );
};

export default FileInputDragAndDrop;

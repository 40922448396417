import React, { useMemo } from "react";
import { SzRadioButton } from "@suezenv/react-theme-components";
import { FieldProps } from "react-jsonschema-form";
import { JSONSchema6 } from "json-schema";
import "./SzRadio-button.form.scss";

const SzRadioButtonForm: React.FC<FieldProps> = ({ value, onChange, uiSchema, disabled, schema }) => {
    const newSchema: JSONSchema6 & any = schema;

    const options = useMemo(() => {
        if (newSchema.labels) {
            return newSchema.enum!.map((el: string, index: number) => {
                return { value: el, label: newSchema.labels[index] };
            });
        }
        return newSchema.enum!.map((option: string) => {
            return { value: option, label: option };
        });
    }, [newSchema.enum, newSchema.labels]);

    return (
        <SzRadioButton
            value={value}
            onChange={onChange}
            name={"noIconOpt"}
            choices={options}
            className="vertical-radio"
        />
    );
};
export default SzRadioButtonForm;

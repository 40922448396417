import React, { useCallback, useContext } from "react";
import { SzBox, SzCheckbox, SzIcon, SzTypographie } from "@suezenv/react-theme-components";
import SignalContext from "../../../context/signal.context";
import { dateToString, stringToDate } from "../../../helpers/date.util";
import { useTranslationWithPrefix } from "../../../hooks/translation";
import { RequestTypeReference } from "../../../type/organization.type";
import { SignalRequest } from "../../../type/signal.type";
import "./signal-list-item.scss";

interface ISignalListItem {
    signal: SignalRequest;
    category?: RequestTypeReference;
    checked: boolean;
    onItemChange: (signal: SignalRequest) => void;
    index: number;
}

const SignalListItem: React.FC<ISignalListItem> = ({ signal, category, checked, onItemChange, index }) => {
    const signalContext = useContext(SignalContext);
    const trans = useTranslationWithPrefix("signalement", "list_signal");
    const goSignalDetail = useCallback(() => {
        if (signalContext.goSignalDetail) {
            signalContext.goSignalDetail(signal.id);
        }
    }, [signal.id, signalContext]);

    const onChange = () => {
        onItemChange(signal);
    };

    return (
        <div className="signal-list-item row my-2 ml-3 px-5 align-items-center">
            <div className="col mr-2">

                {/* item list */}
                <SzBox className={"row signal-item-info "+(index%2 ? "bg-white" : "bg-light")+" py-3 px-2"}>
                    <div className="col-5 mb-2">
                        <SzTypographie className="mb-0" weight="medium" variant="body">
                            {category?.label}
                        </SzTypographie>
                        <SzTypographie variant="caption">
                            {dateToString(stringToDate(signal.createdAt), "DD/MM/YYYY")}
                        </SzTypographie>
                    </div>
                    <div className="col-3 mb-2">
                        <div className="row">
                            <div className="col-auto">
                                <SzIcon variant="line" icon="pin-location-1" />
                            </div>
                            <div className="col pl-0">
                                <SzTypographie variant="caption">{trans("location")}</SzTypographie>
                                <SzTypographie className="mb-0" weight="medium" variant="body">
                                    {signal.address.city}
                                </SzTypographie>
                            </div>
                        </div>
                    </div>
                    <div className={"col-3 mb-2 "+(signalContext.enableBatchExport ? "col-lg-2" : "col-lg-3")}>
                        <div className="row">
                            <div className="col-auto">
                                <span className={`dot ${signal.currentStatus.status.id}`} />
                            </div>
                            <div className="col pl-0">
                                <SzTypographie variant="caption">{trans("status")}</SzTypographie>
                                <SzTypographie className="mb-0" weight="medium" variant="body">
                                    {trans(signal.currentStatus.status.id)}
                                </SzTypographie>
                            </div>
                        </div>
                    </div>
                    <div className="col-1 mb-2 btn" onClick={goSignalDetail}>
                        <SzIcon variant="line" icon="navigation-menu-4" />
                    </div>

                    {signalContext.enableBatchExport && (
                        <div className="col-lg-1 d-none d-lg-block pt-2">
                            <SzCheckbox id={signal.id} name={signal.id} label="" checked={checked} onChange={onChange} />
                        </div>
                    )}
                </SzBox>
            </div>
        </div>
    );
};

export default SignalListItem;

import { AxiosResponse } from "axios";
import { LatLngTuple } from "leaflet";
import { IAddress } from "../type/Address.type";
import qs from "qs";
// @todo modify here with request app.
import request from "./AppRequest";
import { config } from "../config";

const MAP_GEO_API_URL = "https://api-adresse.data.gouv.fr/search/";
const MAP_REVERSE_GEO_API_URL = "https://api-adresse.data.gouv.fr/reverse/";
const GEOGRAFICAL_PRIORITY = { lat: 48.8436, lon: 2.2458 };

export class GeoService {
  public static searchAddress = async (address: string): Promise<IAddress[]> => {
    let items: IAddress[] = [];
    await request
      .get<IAddress[]>(
        `${MAP_GEO_API_URL}?q=${address}&lat=${GEOGRAFICAL_PRIORITY.lat}&lon=${GEOGRAFICAL_PRIORITY.lon}`,
        true,
        true,
        true,
        {},
        true,
      )
      .then((response: AxiosResponse) => {
        items = response.data.features.map((value: any): IAddress => {
          let address;
          if ('municipality' === value.properties.type) {
            address = value.properties.city + ' ' + value.properties.postcode;
          } else {
            address = value.properties.label;
          }
          return {
            latitude: value.geometry.coordinates[1],
            longitude: value.geometry.coordinates[0],
            address: address,
            number: value.properties.housenumber,
            street: value.properties.street ? value.properties.street : value.properties.name,
            postalCode: value.properties.postcode,
            city: value.properties.city,
            country: "France",
            type: value.properties.type,
          };
        });
      });
    return items;
  };

  public static getAddressFromLatLang = async (lat: number, lng: number): Promise<IAddress[]> => {
    let items: IAddress[] = [];
    await request
      .get<IAddress[]>(`${MAP_REVERSE_GEO_API_URL}?lon=${lng}&lat=${lat}`, true, true, true, {}, true)
      .then((response: AxiosResponse) => {
        items = response.data.features.map((value: any): IAddress => {
          return {
            latitude: value.geometry.coordinates[1],
            longitude: value.geometry.coordinates[0],
            address: value.properties.label,
            number: value.properties.housenumber,
            street: value.properties.street ? value.properties.street : value.properties.name,
            postalCode: value.properties.postcode,
            city: value.properties.city,
            country: "France",
            type: "",
          };
        });
      });
    return items;
  };

  public static isPointInDepartement = async (
    geoserver: {
      url: string;
      workspace: string;
      layer: string;
      authkey?: string;
    },
    point: LatLngTuple,
  ): Promise<boolean> => {
    const query = {
      version: "1.0.0",
      outputFormat: "application/json",
      typeName: `${geoserver.workspace}:${geoserver.layer}`,
      request: "GetFeature",
      service: "WFS",
      authKey: geoserver.authkey,
      CQL_FILTER: (`INTERSECTS(%geoserverPropertyName%, POINT(${point[1]} ${point[0]}))`)
        .replace('%geoserverPropertyName%', config.geoserverPropertyName),
    };
    const queryString = qs.stringify(query);
    return await request
      .get<boolean>(`${geoserver.url}/wfs?${queryString}`, false, true, true, {}, true)
      .then((response: AxiosResponse<any>) => {
        return response.data.numberReturned > 0;
      });
  };
}

import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { SzBox, SzButton, SzStepper } from "@suezenv/react-theme-components";
import { useTranslationWithPrefix } from "../../../../hooks/translation";
import { ISubmitContext } from "./context/submit-context";
import ComplementForm from "./form/complement.form";

export interface IPropertie {
    content: React.ReactElement;
    name: string;
    disabled: boolean;
    readonly: boolean;
}
const ObjectFieldTemplateStepper = ({ properties, schema }: any) => {
    const { submitEnabled, setSubmitEnabled, invalidProperties } = useContext(ISubmitContext);
    const [step, setStep] = useState(0);
    const [displayComplement, setDisplayComplement] = useState<boolean>(false);
    const trans = useTranslationWithPrefix("create_signalement", "stepper");
    const { stepsProperties, header } = useMemo<{ stepsProperties: IPropertie[]; header: string[] }>(() => {
        const mainSteps: any = properties.slice(0, -2);
        return {
            stepsProperties: mainSteps,
            header: mainSteps.map((property: { name: string }) => schema.properties[property.name].title),
        };
    }, [properties]);

    const complementProperties = useMemo<IPropertie[]>(() => {
        return properties.slice(-2);
    }, [properties]);

    const isPreviousDisabled: boolean = step === 0;

    const onNextClicked = (currentIndex: number) => {
        //setNextDisabled(false);
        if (currentIndex + 1 < stepsProperties.length) {
            setStep(currentIndex + 1);
        } else {
            setDisplayComplement(true);
        }
    };
    useEffect(() => {
        if (stepsProperties.length === 0) {
            setDisplayComplement(true);
        }
    }, [stepsProperties]);
    useEffect(() => {
        if (displayComplement) {
            if (!submitEnabled) setSubmitEnabled(true);
        } else {
            if (submitEnabled) setSubmitEnabled(false);
        }
    }, [displayComplement]);

    const isCurrentPropertyValid = useCallback(
        (propertyName: string) => {
            if (invalidProperties.includes(`.${propertyName}`)) {
                return false;
            }
            return true;
        },
        [invalidProperties],
    );
    return (
        <div>
            {displayComplement && stepsProperties.length > 0 && (
                <div className="row">
                    <div className="col-auto">
                        <SzButton
                            variant="tertiary"
                            className="mr-3 pl-1"
                            icon="keyboard-arrow-left"
                            // tslint:disable-next-line: jsx-no-lambda
                            onClick={() => {
                                setDisplayComplement(false);
                            }}
                        >
                            {trans("back_main_form")}
                        </SzButton>
                    </div>
                </div>
            )}
            {!displayComplement ? (
                <SzStepper
                    header={header}
                    activeStep={step}
                    variant="simple"
                    displayProgress={true}
                    className="bg-transparent"
                >
                    <SzStepper.SzHeader />
                    {stepsProperties.map((property: IPropertie, index: number) => (
                        <SzStepper.SzStep key={index} index={index}>
                            <div className="row justify-content-between mb-4">
                                <div className="col-auto pr-0 pl-1">
                                    <SzButton onClick={() => setStep(index - 1)} isDisabled={isPreviousDisabled}>
                                        {trans("previous")}
                                    </SzButton>
                                </div>
                                <div className="col-auto pl-0 pr-1">
                                    <SzButton
                                        onClick={() => onNextClicked(index)}
                                        isDisabled={!isCurrentPropertyValid(property.name)}
                                    >
                                        {trans("next")}
                                    </SzButton>
                                </div>
                            </div>
                            <SzBox className="bg-white">{property.content}</SzBox>
                        </SzStepper.SzStep>
                    ))}
                </SzStepper>
            ) : (
                <div>
                    <ComplementForm properties={complementProperties} />
                </div>
            )}
        </div>
    );
};

export default ObjectFieldTemplateStepper;

import React, { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { SzBox, SzButton, SzTypographie } from "@suezenv/react-theme-components";
import { useTranslationWithPrefix } from "../../../hooks/translation";
import { useOrganization } from "../../../hooks/organization";
import { SignalRequest } from "../../../type/signal.type";
import { RequestTypeReference } from "../../../type/organization.type";
import useSignalCategory from "../../../hooks/signal-category";
import { dateToString, stringToDate } from "../../../helpers/date.util";
import requestTypeReferenceRepository from "../../../services/repository/request-type-reference.repository";
import { UserType } from "../../../type/user.type";

interface ISignalHighlight {
    signal: SignalRequest;
    user: UserType;
}
const SignalHighlight: React.FC<ISignalHighlight> = ({ signal, user }) => {
  const [requestTypes, setRequestTypes] = useState<RequestTypeReference[]>([]);
  const getSignalCategorie = useSignalCategory(requestTypes);
  const organization = useOrganization(user);
  const trans = useTranslationWithPrefix('signalement', 'detail_signal');
  const signalLink = window.location.href;
  useEffect(() => {
    if (organization) {
      requestTypeReferenceRepository
        .getAll(organization.id)
        .then(({ data }) => {
          setRequestTypes(data);
        });
    }
  }, [organization]);
  return (
    <SzBox className='row bg-white p-3 mb-4'>
      <div className='col-12 col-md-10 pb-2'>
        <div className='row'>
          <div className='col-12 col-md-4'>
            <SzTypographie variant='body' weight='medium' className='mb-0'>
              {signal ? getSignalCategorie(signal.requestType)?.label : ''}
            </SzTypographie>
            <SzTypographie variant='caption' className='mb-0'>
              {signal
                ? dateToString(stringToDate(signal.createdAt), 'DD/MM/YYYY')
                : 'XX/XX/XXXX'}
            </SzTypographie>
          </div>
          <div className='col-md-8 d-none d-md-flex'>
            <div className='row'>
              <div className='col-8 col-sm-6'>
                <SzTypographie variant='caption' className='mb-0'>
                  {trans('signal_link')}
                </SzTypographie>
                <SzTypographie
                  variant='body'
                  weight='medium'
                  className='mb-0 out-hidden'
                >
                  <a href={signalLink} target='_blank' rel='noreferrer'>
                    {signalLink}
                  </a>
                </SzTypographie>
              </div>
              <div className='col-2 p-0'>
                <CopyToClipboard text={signalLink} onCopy={undefined}>
                  <SzButton
                    className='p-0'
                    variant='tertiary'
                    icon='common-file-double-2'
                  />
                </CopyToClipboard>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='col-12 col-md-2 '>
        <div className='row justify-content-end'>
          <div className='col-auto'>
            <span className={`dot ${signal?.currentStatus.status.id}`} />
          </div>
          <div className='col pl-0'>
            <SzTypographie variant='caption'>{trans('status')}</SzTypographie>
            <SzTypographie className='mb-0' weight='medium' variant='body'>
              {signal && trans(signal.currentStatus.status.id)}
            </SzTypographie>
          </div>
        </div>
      </div>
    </SzBox>
  );
};
export default SignalHighlight;

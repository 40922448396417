import React from 'react'
import signalService from "../../../../services/api/signal-service";
import { ISignalPicture } from './pictures'

interface ISignalDataPicture {
    picture: ISignalPicture;
}

const FileImage: React.FC<ISignalDataPicture> = ({picture}) => {

    return (
        <svg width="200" height="105"
            xmlns="http://www.w3.org/2000/svg" version="1.1"
            className="img-fluid"
            onClick={() => {
                signalService.downloadAttachment(picture.link).then((response: { data: string; }) => {
                    const link = document.createElement('a');
                    link.setAttribute('download', `${picture.fileName}.${picture.extension}`);
                    link.href = window.URL.createObjectURL(new Blob([response.data]));
                    link.target = "blank";
                    document.body.appendChild(link);
                    link.click();
                })
            }}
        >
            <g >
  <g id="svg_1">
   <path d="m77.47,23.28l-15.94,-15.95c-0.86,-0.86 -2.01,-1.33 -3.22,-1.33l-42.57,0c-2.51,0 -4.55,2.04 -4.55,4.55l0,80.9c0,2.51 2.04,4.55 4.55,4.55l58.52,0c2.51,0 4.55,-2.04 4.55,-4.55l0,-64.95c0,-1.22 -0.48,-2.36 -1.34,-3.22zm-16.4,-12.16l12.61,12.61l-11.33,0c-0.7,0 -1.28,-0.57 -1.28,-1.27l0,-11.34zm13.19,81.88l-58.52,0c-0.85,0 -1.55,-0.69 -1.55,-1.55l0,-80.9c0,-0.86 0.7,-1.55 1.55,-1.55l42.33,0l0,13.46c0,2.35 1.92,4.27 4.28,4.27l13.46,0l0,64.72c0,0.86 -0.7,1.55 -1.55,1.55z" id="svg_2" stroke-linecap="round"/>
   <path d="m68.19,39.32l-26.38,0c-0.83,0 -1.5,-0.67 -1.5,-1.5s0.67,-1.5 1.5,-1.5l26.38,0c0.83,0 1.5,0.67 1.5,1.5s-0.67,1.5 -1.5,1.5z" id="svg_3" stroke-linecap="round"/>
   <path d="m34.46,39.32l-12.65,0c-0.83,0 -1.5,-0.67 -1.5,-1.5s0.67,-1.5 1.5,-1.5l12.65,0c0.83,0 1.5,0.67 1.5,1.5s-0.68,1.5 -1.5,1.5z" id="svg_4" stroke-linecap="round"/>
   <line id="svg_5" x1="-10.25" x2="10.25" y1="6" y2="6"/>
   <path d="m42.3,26.73l-20.49,0c-0.83,0 -1.5,-0.67 -1.5,-1.5s0.67,-1.5 1.5,-1.5l20.49,0c0.83,0 1.5,0.67 1.5,1.5s-0.67,1.5 -1.5,1.5z" id="svg_6" stroke-linecap="round"/>
   <path d="m68.19,50.32l-46.38,0c-0.83,0 -1.5,-0.67 -1.5,-1.5s0.67,-1.5 1.5,-1.5l46.38,0c0.83,0 1.5,0.67 1.5,1.5s-0.67,1.5 -1.5,1.5z" id="svg_7" stroke-linecap="round"/>
   <path d="m48.19,61.32l-26.38,0c-0.83,0 -1.5,-0.67 -1.5,-1.5s0.67,-1.5 1.5,-1.5l26.38,0c0.83,0 1.5,0.67 1.5,1.5s-0.67,1.5 -1.5,1.5z" id="svg_8" stroke-linecap="round"/>
   <path d="m68.19,61.32l-12.65,0c-0.82,0 -1.5,-0.67 -1.5,-1.5s0.68,-1.5 1.5,-1.5l12.65,0c0.83,0 1.5,0.67 1.5,1.5s-0.67,1.5 -1.5,1.5z" id="svg_9" stroke-linecap="round"/>
   <path d="m68.19,72.32l-46.38,0c-0.83,0 -1.5,-0.67 -1.5,-1.5s0.67,-1.5 1.5,-1.5l46.38,0c0.83,0 1.5,0.67 1.5,1.5s-0.67,1.5 -1.5,1.5z" id="svg_10" stroke-linecap="round"/>
   <path d="m68.19,83.32l-12.65,0c-0.82,0 -1.5,-0.67 -1.5,-1.5s0.68,-1.5 1.5,-1.5l12.65,0c0.83,0 1.5,0.67 1.5,1.5s-0.67,1.5 -1.5,1.5z" id="svg_11" stroke-linecap="round"/>
  </g>
 </g>
        </svg>
    ) 
}

export default FileImage
import React from "react";
import { SzBox, SzList, SzIcon, SzTypographie } from "@suezenv/react-theme-components";
import useReplaceNull from "../../../hooks/replace-null";
import { useTranslationWithPrefix } from "../../../hooks/translation";
import { SignalRequest, ExternalData } from "../../../type/signal.type";
import { FORMAT_DATETIME, FORMAT_DATE_FR, stringToFormat, dateToString, stringToDate } from "../../../helpers/date.util";
import { isEmptyArray } from "formik";
import _ from "lodash";

interface ISignalData {
    signal: SignalRequest;
}

const EVENT_DATE_WIDGET = 'eventDate'

const SignalData: React.FC<ISignalData> = ({ signal }) => {
    const trans = useTranslationWithPrefix("signalement", "detail_signal");
    const replaceNull = useReplaceNull("-");
    const getDataValue = (signal: SignalRequest, key: string) => {
        let val = _.isString(signal.data[key])
            ? replaceNull(signal.data[key])
            : replaceNull(signal.data[key].label)

        if (EVENT_DATE_WIDGET === key) {
            return dateToString(stringToDate(val, FORMAT_DATETIME, false), FORMAT_DATETIME)
        }
        return val
    }

    const getExternalValue = (data: ExternalData) => {
        switch (data.type) {
            case 'string': 
            case 'integer': 
            case 'float': 
                return data.value
            case 'iso8601':
                return stringToFormat(data.value, FORMAT_DATE_FR)
            case 'boolean': 
                return data.value ? 'Oui' : 'Non'
            case 'arrayOption':
            case 'arrayString':
                return (
                    <SzList horizontal={false}>
                        {data.value.map((arrayValue: any) => (
                            <SzList.Item
                                active={false}
                                className="border-0 p-0 min-h-0 bg-light list-item-disc"
                                disabled={true}
                                key={arrayValue.id ? arrayValue.id : arrayValue}
                            >
                                {arrayValue.label ? arrayValue.label : arrayValue}
                            </SzList.Item>
                        ))}
                    </SzList>
                );
            default:
                console.warn("User Warning: External data's type '" + data.type + "' is not handled !")
                return;
        } 
    }

    const displayExternalData = (signalExternalData: ExternalData[]) => {
        return (
            <div className="row mb-3 mt-5 mb-3">
                <div className="col">
                    <SzTypographie variant="body" weight="medium" className="mb-0">
                        {trans("complementary_information")}
                    </SzTypographie>
                    <SzBox className="row bg-light p-4">
                       {Object.keys(signalExternalData).map((key: string) => (
                           <div key={key} className="col-12 col-sm-6 col-lg-4 mb-2">
                               <SzTypographie variant="caption">{signalExternalData[key].label}</SzTypographie>
                               <SzTypographie className="mb-0" weight="light" variant="body">
                                    {getExternalValue(signalExternalData[key])}
                               </SzTypographie>
                           </div>
                       ))}
                   </SzBox>
                </div>
            </div>
        );
    }

    return (
        <SzBox className="row bg-white p-3 pt-1 mb-3">
            <div className="col">
                <div className="row mb-3 justify-content-between">
                    <div className="col-12 col-sm mt-4 m-1">
                        <SzTypographie variant="body" weight="medium" className="mb-0">
                            {trans("origin")}
                        </SzTypographie>
                        <SzBox className="row bg-light h-75 align-items-top p-4">
                            <div className="col-12 col-sm m-1 mb-2">
                                <div className="row align-items-center">
                                    <div className="col-auto">
                                        <SzIcon variant="line" icon="people-man-1" />
                                    </div>
                                    <div className="col pl-0">
                                        <SzTypographie variant="caption">{trans("first_last_name")}</SzTypographie>
                                        <SzTypographie className="mb-0 text-uppercase" weight="medium" variant="body">
                                            {signal.applicationUser.lastName}
                                        </SzTypographie>
                                        <SzTypographie className="mb-0 text-capitalize" weight="medium" variant="body">
                                            {signal.applicationUser.firstName}
                                        </SzTypographie>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-sm m-1 mb-2">
                                <div className="col pl-0">
                                        <SzTypographie variant="caption">{trans("phonemail")}</SzTypographie>
                                        <SzTypographie className="mb-0" weight="medium" variant="body">
                                            {signal.applicationUser.phone}
                                        </SzTypographie>
                                        <SzTypographie className="mb-0" weight="medium" variant="body">
                                            {signal.applicationUser.email}
                                        </SzTypographie>
                                </div>
                            </div>
                        </SzBox>
                    </div>
                    <div className="col-12 col-sm mt-4 m-1">
                        <SzTypographie variant="body" weight="medium" className="mb-0">
                            {trans("localization")}
                        </SzTypographie>
                        <SzBox className="row bg-light  h-75 align-items-top p-4">
                            <div className="col-auto mb-2">
                                <SzTypographie variant="caption">{trans("city")}</SzTypographie>
                                <SzTypographie className="mb-0" weight="medium" variant="body">
                                    {replaceNull(signal.address.city)}
                                </SzTypographie>
                            </div>
                            <div className="col-auto mb-2">
                                <SzTypographie variant="caption">{trans("zip_code")}</SzTypographie>
                                <SzTypographie className="mb-0" weight="medium" variant="body">
                                    {replaceNull(signal.address.postalCode)}
                                </SzTypographie>
                            </div>
                            <div className="col-auto mb-2">
                                <SzTypographie variant="caption">{trans("number")}</SzTypographie>
                                <SzTypographie className="mb-0" weight="medium" variant="body">
                                    {replaceNull(signal.address.number)}
                                </SzTypographie>
                            </div>
                            <div className="col mb-2">
                                <SzTypographie variant="caption">{trans("street")}</SzTypographie>
                                <SzTypographie className="mb-0" weight="medium" variant="body">
                                    {replaceNull(signal.address.street)}
                                </SzTypographie>
                            </div>
                        </SzBox>
                    </div>
                </div>
                <div className="row mb-3 mt-5 mb-3">
                    <div className="col">
                        <SzTypographie variant="body" weight="medium" className="mb-0">
                            {trans("detail")}
                        </SzTypographie>
                        <SzBox className="row bg-light align-items-center p-4 ">
                            {Object.keys(signal.data).map((key: string) => (
                                <div key={key} className="col-12 col-sm-6 col-lg-4 mb-2">
                                    {/** Key title use dynamic label instead of translation file */}
                                    <SzTypographie variant="caption">{signal.data[key].keyTranslation??''}</SzTypographie>
                                    <SzTypographie className="mb-0" weight="medium" variant="body">
                                        {getDataValue(signal, key)}
                                    </SzTypographie>
                                </div>
                            ))}
                            <div className="col-12">
                                <SzTypographie variant="caption">{trans("description")}</SzTypographie>
                                <SzTypographie className="mb-0" weight="medium" variant="body">
                                    {replaceNull(signal.comment)}
                                </SzTypographie>
                            </div>
                        </SzBox>
                    </div>
                </div>
                {signal.externalData && !isEmptyArray(signal.externalData) && displayExternalData(signal.externalData)}
            </div>
        </SzBox>
    );
};
export default SignalData;

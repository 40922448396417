import { getContract } from '../services/user.util'
import { ContractType } from '../type/security.type'
import { UserType } from '../type/user.type'

export const useOrganization = (user?: UserType): ContractType | undefined => {
  if (user) {
    return getContract(user)
  }
  return undefined
}

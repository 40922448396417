import dayjs from "dayjs";

export const FORMAT_DATE = "YYYY-MM-DD";
export const FORMAT_DATE_FR = "DD/MM/YYYY";
export const FORMAT_DATETIME = FORMAT_DATE + ' HH:mm'

export const dateToString = (date: Date, format: string = FORMAT_DATE) => {
    return dayjs(date).format(format);
};

export const stringToDate = (date: string, format: string = FORMAT_DATE, useTimezone: boolean = true) => {
    if (useTimezone) {
        return dayjs(date, format).toDate();
    }
    return dayjs(date.replace(/(\.000)?Z$/, ''), format).toDate();
};

export const stringToFormat = (date: string, format: string = FORMAT_DATE) => {
    return dateToString(stringToDate(date, format, false), format)
};
import React, { useContext, useEffect, useState } from "react";
import BaseForm from "./base.form";
import { RequestTypeReference } from "../../../../../type/organization.type";
import fromSchemaService from "../../../../../services/api/from-schema-service";
import { FormProps } from "@rjsf/core";
import SignalContext from "../../../../../context/signal.context";
import { useOrganization } from "../../../../../hooks/organization";

interface IRequestTypeDynamicFormProps {
    requestType: RequestTypeReference;
    onSubmitForm: FormProps<any>["onSubmit"];
    goBack: () => void;
}

const RequestTypeDynamicForm: React.FC<IRequestTypeDynamicFormProps> = ({ requestType, onSubmitForm, goBack }) => {
    const [schema, setSchema] = useState();
    const [uiSchema, setUiSchema] = useState();
    const signalContext = useContext(SignalContext);
    const organization = useOrganization(signalContext.user);
    useEffect(() => {
        async function schemaDataFetch() {
            if (organization) {
                const schemaResponse = await fromSchemaService.getSchema(organization?.id, requestType.requestType);
                const uiShemaResponse = await fromSchemaService.getUiSchema(organization?.id, requestType.requestType);
                setSchema(schemaResponse.data);
                setUiSchema(uiShemaResponse.data);
            }
        }

        schemaDataFetch();
    }, [requestType.requestType, organization]);

    const onChange = () => {};

    return (
        <div className="row h-100 justify-content-around">
            {uiSchema && schema && (
                <div className="col-9 col-md-5 my-auto px-0">
                    <BaseForm
                        uiSchema={uiSchema}
                        schema={schema}
                        onChange={onChange}
                        onSubmit={onSubmitForm}
                        cancel={goBack}
                    />
                </div>
            )}
        </div>
    );
};

export default RequestTypeDynamicForm;

import React, { useCallback, useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { SzIcon } from "@suezenv/react-theme-components";
import { useTranslation } from "react-i18next";
import "./file-uploader.scss";
import { ReactComponent as SvgFile } from './fileimg.svg'

export interface FileUploaderProps {
    onChange: (files: File[]) => void;
    dragAndDropText: string;
    showCurrentFiles?: boolean;
    canDeleteFile?: boolean;
    multiple?: boolean;
    className?: string;
    allowedExtensions?: string[];
}

const FileUploader: React.FC<FileUploaderProps> = ({
    onChange,
    dragAndDropText,
    canDeleteFile = false,
    showCurrentFiles = true,
    multiple = true,
    className = "",
    allowedExtensions,
}) => {
    const [files, setFiles] = useState<File[]>([]);
    const { t } = useTranslation("indicator");
    const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);

    const deleteFile = useCallback(
        (fileName: string) => {
            const listFiles = files.filter((file) => file.name !== fileName);
            setFiles([...listFiles]);
        },
        [files],
    );

    useEffect(() => {
        onChange(files);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [files]);

    const onDropAccepted = (addedFiles: any) => {
        if (multiple) {
            const allFiles = files.concat(addedFiles);
            onChange(allFiles);
            setFiles([...allFiles]);
        } else {
            onChange([...addedFiles]);
            setFiles([...addedFiles]);
        }
    };

    return (
        <div className={`file-uploader ${className}`}>
            <Dropzone
                multiple={multiple}
                accept={allowedExtensions}
                onDropAccepted={onDropAccepted}
                // tslint:disable-next-line: jsx-no-lambda
                onDropRejected={() => setShowErrorMessage(true)}
            >
                {({ getRootProps, getInputProps }) => (
                    <section className={`dropzone-wrapper`}>
                        <div {...getRootProps()}>
                            <input {...getInputProps()} />
                            <div className="dropzone-text">
                                <div>{dragAndDropText}</div>
                                <div>
                                    <SzIcon icon="camera-1" variant="line" />
                                    {showErrorMessage && (
                                        <div className="alert bg-warning">{t("unallowed_extension")}</div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </section>
                )}
            </Dropzone>
            {showCurrentFiles && (
                <div className="dropzone-current-files">
                    {files.map((file: File) => (
                        <div className="current-file-item" key={file.name}>
                            <div className="row">
                                <div className="col-2 h-100">
                                    {file.type.match(/^image\//) && <img className="mr-2" src={URL.createObjectURL(file)} alt="" />}
                                    {!file.type.match(/^image\//) && <SvgFile />}
                                </div>
                                <div className="col pr-2 m-auto">{file.name}</div>
                                {canDeleteFile && (
                                    <div className="col-auto m-auto">
                                        <SzIcon
                                            className="current-file-item-delete"
                                            icon="remove"
                                            variant="line"
                                            // tslint:disable-next-line: jsx-no-lambda
                                            onClick={() => deleteFile(file.name)}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default FileUploader;

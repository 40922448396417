import React, { useContext, useEffect, useState } from 'react';
import { RequestTypeReference } from '../../../type/organization.type';
import RequestTypeReferenceRepository from '../../../services/repository/request-type-reference.repository';
import { SzButton, SzIcon } from '@suezenv/react-theme-components';
import { useTranslationWithPrefix } from '../../../hooks/translation';
import { useOrganization } from '../../../hooks/organization';
import SignalContext from '../../../context/signal.context';

interface Props {
  onSelect: (requestType: RequestTypeReference) => void;
}

const FirstStep: React.FC<Props> = ({ onSelect }) => {
  const [requestTypes, setRequestTypes] = useState<RequestTypeReference[]>([]);
  const trans = useTranslationWithPrefix('create_signalement', 'first_step');
  const signalContext = useContext(SignalContext);
  const organization = useOrganization(signalContext.user);

  useEffect(() => {
    if (organization) {
      RequestTypeReferenceRepository.getAll(organization.id).then(
        ({ data }) => {
          setRequestTypes(data);
        },
      );
    }
  }, [organization]);

  return (
    <div className='first-step-container'>
      <div className='d-flex justify-content-around bg-white py-2 align-items-baseline'>
        <h2>{trans('title')}</h2>
        <div>
          <SzIcon icon='information-circle' variant='line' />
        </div>
      </div>

      <div className='text-center pt-1'>
        <span className='text-black-50 fs-07'>{trans('sub_title')}</span>
      </div>

      <div className='row justify-content-around'>
        <div className='col-10 col-md-8 my-auto'>
          {requestTypes.map((requestType) => {
            return (
              <SzButton
                key={requestType.id}
                variant='secondary'
                className='my-2 w-100'
                onClick={() => onSelect(requestType)}
              >
                {requestType.label}
              </SzButton>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FirstStep;

import { Request } from "../Request";
import instance from "../AppRequest";
import { AxiosResponse } from "axios";
import { IPin } from "../../type/resource.type";

class PinService {
    private axiosService: Request;

    /**
     * @param request
     */
    constructor(request: any) {
        this.axiosService = request;
    }

    getAll(): Promise<AxiosResponse<IPin[]>> {
        return this.axiosService.get(`/api/resources/icon/pin`);
    }
}

export default new PinService(instance);

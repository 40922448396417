import { useCallback, useEffect, useState } from "react";
import pinService from "../services/api/pin-service";

import { IPin } from "../type/resource.type";

const usePin = () => {
    const [pins, setPins] = useState<IPin[]>([]);
    useEffect(() => {
        pinService
            .getAll()
            .then(({ data }) => {
                setPins(data);
            })
            .catch(() => {
                setPins([]);
            });
    }, []);
    const getSignalCategory = useCallback(
        (pinCode?: string) => {
            if (!pinCode) {
                return;
            }
            const filtredPin = pins.filter((pin: IPin) => pin.pinCode === pinCode);
            return filtredPin.length > 0 ? filtredPin[0] : undefined;
        },
        [pins],
    );
    return getSignalCategory;
};

export default usePin;

import React from "react";
import { UserType } from "../type/user.type";
interface ISignalContext {
    geoServer?: {
        url: string;
        workspace: string;
        layer: string;
        authkey: string;
    };
    user: UserType;
    goSignalDetail: (signalId: string) => void;
    enableBatchExport: boolean;
}
export const SignalContext = React.createContext<Partial<ISignalContext>>({});

export default SignalContext;

import { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { SzBox, SzButton, SzIcon, SzTypographie } from "@suezenv/react-theme-components";
import { useTranslationWithPrefix } from "../../hooks/translation";
import signalService from "../../services/api/signal-service";
import pdfService from "../../services/pdfService";
import { SignalRequest, StatusChangeActionType } from "../../type/signal.type";
import SignalHighlight from "./sections/signal-highlight";
import SignalData from "./sections/signal-data";
import SignalTabs from "./sections/signal-tabs";
import ActionModal from "./sections/action-modal";
import { UserType } from "../../type/user.type";
import { useIsHasSignalService, useIsHasOperatorService } from "../../hooks/service";
import Unauthorized from "../message/unauthorized";
import "./signal-detail.scss";
import i18n from "../../../i18n";
import { I18nextProvider } from "react-i18next";
import { ISignalCustomParams } from "../../type/signal.type";
interface ISignalDetail {
    signalId: string;
    user: UserType;
    onBackClicked: () => void;
    customParams?: ISignalCustomParams;
    customAction: () => void;
}



export const SignalDetail: React.FC<ISignalDetail> = ({ signalId, user, onBackClicked, customParams, customAction }): JSX.Element => {

    
    const [showLModal, setShowModal] = useState(false);
    const [tab, setTab] = useState("comment-tab");
    const [modalAction, setModalAction] = useState<StatusChangeActionType>("comment");
    const [signal, setSignal] = useState<SignalRequest>();
    const isHasSignalService = useIsHasSignalService(user);
    const isHasOperatorService = useIsHasOperatorService(user);


    const bodyRef = React.createRef<any>();
    const createPdf = () => {
        const mainElement: HTMLElement = document.getElementById("pdf-export") || document.body;
        const mainElementClone = mainElement.cloneNode(true);
        const hiddenTab = tab === "comment-tab" ? "picture-tab" : "comment-tab";
        const currentTab = tab;
        setTab(hiddenTab);
        setTimeout(() => {
            const hiddenTabElement: any = document.querySelector(`.${hiddenTab}`);
            const hiddenTabElementClone = hiddenTabElement.cloneNode(true);
            const exportingElement = document.createElement("div");
            mainElementClone.appendChild(hiddenTabElementClone);
            exportingElement.setAttribute("id", "temporay-element");
            exportingElement.appendChild(mainElementClone);
            const addComment: HTMLElement | null = exportingElement.querySelector(`.add-comment`);
            if (addComment) {
                addComment.style.display = "none";
            }
            document.body.appendChild(exportingElement);
            pdfService.createPdf(
                exportingElement,
                trans("exported_filename", {
                    number: signalId,
                }),
                () => {
                    const tmpElement = document.getElementById("temporay-element");
                    if (tmpElement) {
                        tmpElement.remove();
                    }
                    setTab(currentTab);
                },
            );
        }, 1000);
    };
    const trans = useTranslationWithPrefix("signalement", "detail_signal");
    useEffect(() => {
        signalService.getOne(signalId).then((response: AxiosResponse<SignalRequest>) => {
            setSignal(response.data);
        });
    }, [signalId]);
    const showActionModal = (actionId: StatusChangeActionType) => {
        setModalAction(actionId);
        setShowModal(true);
    };
    const updateStatus = (action: StatusChangeActionType, comment: string) => {
        if (signal) {
            let actionPromise = null;
            if (action === "comment") {
                actionPromise = signalService.addComment(signal.id, comment);
            } else {
                actionPromise = signalService.updateStatus(signal.id, action, comment);
            }
            actionPromise.then(() => {
                signalService.getOne(signalId).then((response: AxiosResponse<SignalRequest>) => {
                    setSignal(response.data);
                });
            });
        }
    };

    return (
        <I18nextProvider i18n={i18n}>
            {isHasSignalService ? (
                <div className="signal-detail">
                    <div className="signal-detail-container mb-3">
                        <SzBox className={`signal-detail-body mt-3 mb-5 mx-4`} tag="div">
                            <div className="row">
                                <div className="col">
                                    <div className="row mb-4">
                                        <div className="col-auto">
                                            <SzButton
                                                variant="tertiary"
                                                className="mr-3 pl-1"
                                                icon="keyboard-arrow-left"
                                                // tslint:disable-next-line: jsx-no-lambda
                                                onClick={onBackClicked}
                                            >
                                                {trans("back")}
                                            </SzButton>
                                        </div>
                                    </div>
                                    <div className="row header justify-content-between">
                                        <div className="col">
                                            <SzTypographie variant="h1" weight="regular" className="mb-0">
                                                {trans("title")}
                                            </SzTypographie>
                                            <SzTypographie variant="caption" weight="medium" className="mb-0">
                                                <SzIcon className="icon-sm" variant="line" icon="common-file-text" />
                                                {trans("sub_title")}
                                            </SzTypographie>
                                        </div>
                                        {isHasOperatorService && (
                                            <div className="col-auto d-none d-md-flex">
                                                <SzButton
                                                    variant="tertiary"
                                                    className="mr-3"
                                                    icon="download-bottom"
                                                    onClick={createPdf}
                                                >
                                                    {trans("export_signal")}
                                                </SzButton>
                                                {signal?.currentStatus.status.id === "new" && (
                                                    <SzButton
                                                        variant="secondary"
                                                        icon="remove"
                                                        className="mr-3"
                                                        // tslint:disable-next-line: jsx-no-lambda
                                                        onClick={() => showActionModal("rejected")}
                                                    >
                                                        {trans("reject_signal")}
                                                    </SzButton>
                                                )}
                                                {signal?.currentStatus.status.id === "new" && (
                                                    <SzButton
                                                        className="mr-3"
                                                        icon="check-1"
                                                        // tslint:disable-next-line: jsx-no-lambda
                                                        onClick={() => showActionModal("approved")}
                                                    >
                                                        {trans("approuve_signal")}
                                                    </SzButton>
                                                )}
                                                {signal?.currentStatus.status.id === "approved" && (
                                                    <SzButton
                                                        className="mr-3"
                                                        icon="remove-square"
                                                        // tslint:disable-next-line: jsx-no-lambda
                                                        onClick={() => showActionModal("closed")}
                                                    >
                                                        {trans("close_signal")}
                                                    </SzButton>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    {signal && (
                                        <div id="pdf-export" ref={bodyRef} className="pdf-export">
                                            <SignalHighlight signal={signal} user={user} />
                                            <SignalData signal={signal} />
                                            <SignalTabs
                                                signal={signal}
                                                selectedTab={tab}
                                                changeTab={setTab}
                                                addComment={() => showActionModal("comment")}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </SzBox>
                        <ActionModal
                            show={showLModal}
                            action={modalAction}
                            setShow={setShowModal}
                            validate={updateStatus}
                            customParams={customParams}
                            customAction={customAction}
                        />
                    </div>
                </div>
            ) : (
                <Unauthorized />
            )}
        </I18nextProvider>
    );
};

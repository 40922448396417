import { useCallback } from "react";
import { RequestTypeReference } from "../type/organization.type";

const useSignalCategory = (requestTypes: RequestTypeReference[] = []) => {
    const getSignalCategory = useCallback(
        (requestTypeId: string) => {
            const filtredRequestType = requestTypes.filter(
                (requestType: RequestTypeReference) => requestType.requestType === requestTypeId,
            );
            return filtredRequestType.length > 0 ? filtredRequestType[0] : undefined;
        },
        [requestTypes],
    );
    return getSignalCategory;
};

export default useSignalCategory;

import React, { useEffect, useState } from "react";
import { SzCheckbox } from "@suezenv/react-theme-components";
import "./SzMultipleCheckbox.scss";
import { useTranslationWithPrefix } from "../../hooks/translation";

export interface OptionItem {
    value: any;
    label: string;
}

export interface ISzMultipleCheckbox {
    options: OptionItem[];
    name: string;
    onChange: (name: string, values: OptionItem[]) => void;
    values: OptionItem[];
    showMultipleCheck?: boolean;
    className?: string;
    postRender?: (option: OptionItem) => JSX.Element; // add render after checkbo
}

const SzMultipleCheckbox: React.FC<ISzMultipleCheckbox> = ({
    postRender,
    className = "",
    options,
    name,
    values,
    onChange,
    showMultipleCheck = true,
}) => {
    const [checked, setChecked] = useState<OptionItem[]>([]);
    const trans = useTranslationWithPrefix("common", "form.checkbox_multiple");

    const onCheckboxChange = (e: any, option: OptionItem) => {
        let newChecked: OptionItem[] = checked;
        if (e.target.checked) {
            const index = checked.findIndex((checked) => checked.value === option.value);
            if (index === -1) {
                newChecked = [...checked, option];
            }
        } else {
            const index = checked.findIndex((checked) => checked.value === option.value);
            if (index !== -1) {
                newChecked = checked.filter((_, i) => index !== i);
            }
        }

        setChecked(newChecked);
        onChange(name, newChecked);
    };

    const isChecked = (value: any) => {
        return undefined !== checked.find((checked) => checked.value === value);
    };

    const checkAll = () => {
        setChecked([...options]);
        onChange(name, [...options]);
    };

    const uncheckAll = () => {
        setChecked([]);
        onChange(name, []);
    };
    useEffect(() => {
        setChecked(values);
    }, [values]);

    return (
        <div className={`sz-multiple-checkbox ${className}`}>
            {showMultipleCheck && checked.length === 0 && (
                <span className="multiple-check check-all" onClick={checkAll}>
                    {trans("check_all")}
                </span>
            )}
            {showMultipleCheck && checked.length !== 0 && (
                <span className="multiple-check uncheck-all" onClick={uncheckAll}>
                    {trans("uncheck_all")}
                </span>
            )}

            {options.map((option) => (
                <div className="sz-multiple-checkboxinput-wrapper" key={option.value}>
                    <SzCheckbox
                        className="d-inline-block"
                        id={option.value}
                        label={option.label}
                        name={option.value}
                        checked={isChecked(option.value)}
                        onChange={(e) => onCheckboxChange(e, option)}
                    />
                    {postRender ? postRender(option) : <div />}
                </div>
            ))}
        </div>
    );
};

export default SzMultipleCheckbox;

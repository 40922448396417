import React from "react";

interface ISubmitContext {
    invalidProperties: string[];
    submitEnabled: boolean;
    setSubmitEnabled: (value: boolean) => void;
}
export const ISubmitContext = React.createContext<ISubmitContext>({
    submitEnabled: false,
    setSubmitEnabled: () => {},
    invalidProperties: [],
});

export default ISubmitContext;

import React, { useState } from "react";
import { SzButton, SzCheckbox, SzInput, SzModal } from "@suezenv/react-theme-components";
import { useTranslationWithPrefix } from "../../../hooks/translation";
import { StatusChangeActionType } from "../../../type/signal.type";
import "./action-modal.scss";
import { ISignalCustomParams } from "../../../type/signal.type";
import { CURRENT_ACTION_IS_APPROVED } from "../../../constants/services";

export interface IActionModal {
    action: StatusChangeActionType;
    show: boolean;
    setShow: (flag: boolean) => void;
    validate: (action: StatusChangeActionType, comment: string) => void;
    customParams?: ISignalCustomParams;
    customAction: () => void;
}
const ActionModal: React.FC<IActionModal> = ({ show, action, setShow, validate, customParams, customAction }) => {
    const [checked, setChecked] = useState<string>("");
    const trans = useTranslationWithPrefix("signalement", "detail_signal.modal");
    const [comment, setComment] = useState("");

    const onValidateClicked = () => {
        setShow(false);
        validate(action, checked ? trans(`${action}.${checked}`) : comment);
        setComment("");
    };
    const onCancelClicked = () => {
        if (checked) {
            setChecked("");
        }
        setShow(false);
        setComment("");
    };
    const onCheckBoxChange = (e: any) => {
        if (e.target.name === checked) {
            setChecked("");
        } else {
            setChecked(e.target.name);
            setComment("");
        }
    };
    const onChangeInput = (e: any) => {
        if (checked) {
            setChecked("");
        }
        setComment(e.target.value);
    };
    return (
        <SzModal
            className="action-modal"
            centered={true}
            title={trans(`${action}.title`)}
            show={show}
            handleClose={onCancelClicked}
            size={"xl"}
        >
            <div className="row justify-content-around">
                <div className="col-8">
                    {action === "rejected" && (
                        <div className="row">
                            <div className="col">
                                <SzCheckbox
                                    id="radio"
                                    className="mb-2"
                                    name="raison_1"
                                    label={trans(`${action}.raison_1`)}
                                    type="radio"
                                    checked={checked === "raison_1"}
                                    onChange={onCheckBoxChange}
                                />
                                <SzCheckbox
                                    id="radio-2"
                                    className="mb-2"
                                    name="raison_2"
                                    label={trans(`${action}.raison_2`)}
                                    type="radio"
                                    checked={checked === "raison_2"}
                                    onChange={onCheckBoxChange}
                                />
                                <SzCheckbox
                                    id="radio-3"
                                    className="mb-2"
                                    name="raison_3"
                                    label={trans(`${action}.raison_3`)}
                                    type="radio"
                                    checked={checked === "raison_3"}
                                    onChange={onCheckBoxChange}
                                />
                            </div>
                        </div>
                    )}
                    <SzInput
                        className="mb-5"
                        name="input"
                        value={comment}
                        onChange={onChangeInput}
                        label={trans(`${action}.input_label`)}
                        placeholder={trans(`input_placeholder`)}
                        required={action === "comment"}
                    />
                    <div className="row justify-content-between">
                        <div className="col-auto">
                            <SzButton variant="secondary" className="mr-3" icon="remove" onClick={onCancelClicked}>
                                {trans(`cancel_btn`)}
                            </SzButton>
                        </div>
                        {
                        customParams?.isDisplayCreateRequestAndCloseButton &&  (CURRENT_ACTION_IS_APPROVED === action) && (<div className="col-auto">
                            <SzButton
                                className="mr-3"
                                icon="messages-bubble-edit"
                                onClick={customAction}
                            >
                                {trans('customaction_btn')}
                            </SzButton>
                        </div>)
                        }
                        <div className="col-auto">
                            <SzButton
                                className="mr-3"
                                icon="messages-bubble-edit"
                                isDisabled={!comment && action === "comment"}
                                onClick={onValidateClicked}
                            >
                                {trans(`valide_btn`)}
                            </SzButton>
                        </div>
                    </div>
                </div>
            </div>
        </SzModal>
    );
};

export default ActionModal;

import React from "react";
import { SzTextArea } from "@suezenv/react-theme-components";
import { FieldProps } from "react-jsonschema-form";

const SzTextareaForm: React.FC<FieldProps> = (props) => {
    const { schema, onChange, uiSchema, value = "" } = props;

    return (
        <SzTextArea
            error={props.rawErrors?.length > 0}
            label={schema.title}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            {...uiSchema.options}
        />
    );
};

export default SzTextareaForm;
